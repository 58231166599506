<template>
  <div style="text-align: center; position: relative">
    <button class="close-button" @click.stop="$emit('close')">X</button>
    <PlanSelection
      v-if="currentStep === 'planSelection'"
      @planSelected="handlePlanSelected"
    />
    <SubscriptionProcessing
      v-else-if="currentStep === 'subscriptionProcessing'"
      :selectedPlan="selectedPlan"
      @backToPlanSelection="currentStep = 'planSelection'"
    />
  </div>
</template>

<script>
import PlanSelection from "@/components/ManagePlan/PlanSelection.vue";
import SubscriptionProcessing from "@/components/ManagePlan/SubscriptionProcessing.vue";

export default {
  data() {
    return {
      currentStep: "planSelection",
      selectedPlan: null,
    };
  },
  methods: {
    handlePlanSelected(plan) {
      this.selectedPlan = plan;
      this.currentStep = "subscriptionProcessing";
    },
  },
  components: {
    PlanSelection,
    SubscriptionProcessing,
  },
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  z-index: 9999;
}

.close-button:hover {
  color: #ff8500;
}
</style>
