<template>
  <div class="terms-container">
    <h1>Subscription Terms and Conditions</h1>
    <p>
      By clicking “Subscribe,” you agree to the following Terms and Privacy
      Policy. Please read these terms carefully before subscribing.
    </p>

    <h2>1. Subscription Overview</h2>
    <p>
      1.1. Your subscription automatically renews at the end of each billing
      cycle unless canceled.
    </p>
    <p>
      1.2. You can cancel your subscription anytime by transitioning to the free
      plan.
    </p>

    <h2>2. Subscription Cancellation</h2>
    <p>
      2.1. To cancel your subscription and move to the free plan, the total
      storage used for home screen images must not exceed 21MB.
    </p>
    <p>
      2.2. If your home screen image storage exceeds 21MB, you must reduce it to
      21MB or less before cancellation can be processed.
    </p>
    <p>
      2.3. Failure to comply with this storage limitation may result in
      continued billing under your current subscription plan.
    </p>

    <h2>3. Refund Policy</h2>
    <p>
      3.1. Refunds are not provided for partial months or unused time in the
      billing cycle upon cancellation.
    </p>
    <p>
      3.2. Cancellation will take effect at the end of the current billing
      cycle.
    </p>

    <h2>4. Privacy Policy</h2>
    <p>
      4.1. By subscribing, you agree that your data will be processed in
      accordance with our Privacy Policy.
    </p>
    <p>
      4.2. We collect and store only the data necessary to provide and improve
      our services.
    </p>

    <h2>5. General Terms</h2>
    <p>
      5.1. We reserve the right to modify these terms at any time. Any changes
      will be communicated to you in advance.
    </p>
    <p>
      5.2. Continued use of the subscription after changes to the terms
      constitutes acceptance of the updated terms.
    </p>

    <p>
      If you have any questions, please contact support at
      <a href="mailto:support@mychelper.com">support@mychelper.com</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsView",
};
</script>

<style scoped>
.terms-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1,
h2 {
  color: #333;
}

p {
  margin: 10px 0;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
