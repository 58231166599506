<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="events-container">
    <h2>Church Events</h2>
    <button class="common-btn" @click="openAddEventForm">+ Add Event</button>

    <div v-if="loading" class="events-container">Loading events...</div>
    <div v-for="event in events" :key="event.id" class="event-card">
      <h3>{{ event.eventName }}</h3>
      <p v-if="event.isRecurring">
        <strong>Happening:</strong>
        {{ formatRecurringPattern(event.event_recurring_pattern) }} at
        {{ formatTime(event.startTime) }}
      </p>
      <p v-else>
        <strong>Date:</strong> {{ formatDate(event.startDate) }} -
        {{ formatDate(event.endDate) || "N/A" }}
      </p>
      <p>{{ event.eventDescription }}</p>
      <button @click="viewEvent(event)">View</button>
      <button @click="editEvent(event)">Edit</button>
      <button @click="deleteEvent(event.id)">Delete</button>
    </div>

    <!-- Pagination -->
    <div class="pagination">
      <button
        @click="fetchEvents(currentPage - 1)"
        :disabled="!pagination.prev_page_url"
      >
        Previous
      </button>
      <button
        @click="fetchEvents(currentPage + 1)"
        :disabled="!pagination.next_page_url"
      >
        Next
      </button>
    </div>

    <!-- Modal -->
    <AddEditEventForm
      v-if="showEventForm"
      :event="selectedEvent"
      :church-id="churchId"
      @close="showEventForm = false"
      @saved="fetchEvents"
    />

    <!-- Event Details Modal -->
    <EventCard
      v-if="showEventModal"
      :event="selectedEvent"
      @close="showEventModal = false"
    />
  </div>
</template>

<script>
import AddEditEventForm from "./AddEditEventForm.vue";
import EventCard from "./EventCard.vue";
import {
  formatRecurringPattern,
  formatDate,
  formatTime,
} from "@/utils/dateUtils";

import axios from "axios";

export default {
  components: {
    AddEditEventForm,
    EventCard,
  },
  props: {
    churchId: {
      type: Number,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      loading: true,
      pagination: {},
      currentPage: 1,
      showEventForm: false,
      showEventModal: false,
      selectedEvent: null,
    };
  },
  methods: {
    formatRecurringPattern,
    formatTime,
    formatDate,
    async fetchEvents(page = 1) {
      this.loading = true;
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${apiBaseUrl}/events/church-events`, {
          params: {
            churchId: this.churchId,
            page,
          },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.events = response.data.data.data;
        this.pagination = response.data.data;
        this.currentPage = page;
      } catch (error) {
        console.error("Failed to fetch events:", error);
      } finally {
        this.loading = false;
      }
    },
    openAddEventForm() {
      this.selectedEvent = null;
      this.showEventForm = true;
    },
    viewEvent(event) {
      this.selectedEvent = event;
      this.showEventModal = true;
    },
    editEvent(event) {
      this.selectedEvent = event;
      this.showEventForm = true;
    },
    async deleteEvent(eventId) {
      if (confirm("Are you sure you want to delete this event?")) {
        try {
          const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
          await axios.delete(`${apiBaseUrl}/events/${eventId}`, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });
          this.fetchEvents(this.currentPage); // Refresh the list
        } catch (error) {
          console.error("Failed to delete event:", error);
        }
      }
    },
  },
  mounted() {
    this.fetchEvents();
  },
};
</script>

<style scoped>
.events-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.event-card {
  border: 1px solid #ddd;
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
button {
  margin-right: 5px;
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
}
</style>
