<template>
  <!-- Display a welcome message with the user's first name -->
  <h1>Welcome, {{ firstName }}</h1>
  <small>We are so glad you are here!</small>
  <div>
    <div>
      <SubscriptionDetails
        :buttonTitle="'Update Plan'"
        @action="handleUpgrade"
      />
    </div>

    <!-- Add the modal component in the template -->
    <VueFinalModal
      v-model="isManagePlanModalVisible"
      content-class="vfm-center-modal"
    >
      <ManagePlan @close="closeManagePlanModal" />
    </VueFinalModal>
  </div>
  <hr />

  <div>
    <p v-if="isMainAdmin">
      You are the <b>Account Holder and Super Admin</b> of
    </p>
    <h2>{{ churchName }}</h2>
    <p v-if="homeScreenAccess">
      Start customizing your Church Home Screen by going to
      <a @click="$emit('select-view', 'AppHomeScreen')"> App Home Screen </a>
    </p>
    <p class="highlighted-text">
      If you are using <b>Branded</b>. You can <b>Download Your App</b> by
      searching it's name on the Apple Store and Google Play
    </p>
    <p>
      Download the
      <a href="https://testflight.apple.com/join/TzyztFbY" target="_blank">
        <span class="brand-color">my</span
        ><span class="highlighted-brand-color">C</span
        ><span class="brand-color">helper</span> App
      </a>
      currently avaiable on TestFlight for iOS
    </p>
    <p>
      Access your church through this id: <b>{{ churchUrlName }}</b>
    </p>
    <p>
      Or scan the QR-Code in the
      <a href="https://testflight.apple.com/join/TzyztFbY" target="_blank">
        <span class="brand-color">my</span
        ><span class="highlighted-brand-color">C</span
        ><span class="brand-color">helper</span> App
      </a>
    </p>
    <div v-if="churchUrlName">
      <div>
        <img :src="qrCodeImage" alt="QR Code" class="small-qrcode" />
      </div>
      <button @click="downloadQRCode">Download QR Code</button>
    </div>
    <p>
      Tip: Download a the high quality QR Code and share it with your church to
      easy access your church data.
    </p>
    <hr />
  </div>
</template>

<script>
import QRCode from "qrcode";
import { VueFinalModal } from "vue-final-modal";
import ManagePlan from "@/views/ManagePlan.vue";

export default {
  name: "ChurchInfo",
  props: {
    churchData: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueFinalModal,
    ManagePlan,
  },
  computed: {
    firstName() {
      return this.churchData.firstName ?? "User";
    },

    churchName() {
      return this.churchData.church.churchName ?? "Church";
    },

    churchUrlName() {
      const env = process.env.VUE_APP_ENV;
      const urlName = this.churchData?.church?.urlName ?? "";
      return env === "sandbox" ? `sandbox.${urlName}` : urlName;
    },

    isMainAdmin() {
      return this.churchData?.isMainAdmin ?? false;
    },

    homeScreenAccess() {
      return this.churchData?.homeScreenAccess || false;
    },

    broadcastAccess() {
      return this.churchData?.broadcastAccess || false;
    },

    churchPeopleAccess() {
      return this.churchData?.churchPeopleAccess || false;
    },

    groupsAccess() {
      return this.churchData?.groupsAccess || false;
    },

    servingGroupsAccess() {
      return this.churchData?.servingGroupsAccess || false;
    },

    publicEventsAccess() {
      return this.churchData?.publicEventsAccess || false;
    },

    adminsAccess() {
      return this.churchData?.adminsAccess || false;
    },
    currentPlan() {
      return (
        this.churchData.church?.subscriptions?.find(
          (sub) => sub.status === "active"
        ) ?? null
      );
    },
  },
  data() {
    return {
      qrCodeImage: "",
      isManagePlanModalVisible: false,
    };
  },
  watch: {
    // Watch for changes in churchData to generate the QR code
    churchData: {
      immediate: true,
      handler() {
        this.generateQRCode();
      },
    },
  },
  emits: ["selectView"],
  methods: {
    goToHome() {
      this.$router.push("/");
    },
    scheduleDemo() {
      window.open("https://calendly.com/jmorris-7", "_blank");
    },
    handleUpgrade() {
      this.isManagePlanModalVisible = true;
    },
    closeManagePlanModal() {
      this.isManagePlanModalVisible = false;
    },
    async generateQRCode() {
      try {
        // Generate the QR code as a data URL (PNG format)
        this.qrCodeImage = await QRCode.toDataURL(this.churchUrlName, {
          width: 1240, // Increase width for better quality
        });
      } catch (error) {
        console.error("Failed to generate QR Code:", error);
      }
    },
    downloadQRCode() {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = this.qrCodeImage;
      link.download = "myChelper_QR_Code.png";
      link.click();
    },
  },
};
</script>

<style scoped>
/* Main container */
.dashboard-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f7f7f7;
}

/* User welcome header */
h1 {
  font-size: 2rem;
  color: #07538f;
  margin-bottom: 1px;
}

h2 {
  color: #07538f;
  margin-bottom: 1px;
}

h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #07538f;
}

small {
  display: block; /* Ensure proper alignment */
  margin-bottom: 5px; /* Reduce spacing */
  font-size: 0.9rem;
  color: #333;
}

p {
  font-size: 1rem;
  color: #333;
}

.highlighted-text {
  background-color: #d6e9f6; /* Light blue shade based on #07538f */
  padding: 10px;
  border-radius: 5px;
  color: #07538f; /* Brand blue color for text */
}

/* Horizontal rule */
hr {
  margin: 20px 0;
  border: 1px solid #e5e5e5;
}

/* Options container for both mobile and desktop */
.options-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}

button {
  padding: 10px;
  font-size: 1rem;
  background-color: #07538f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.active {
  background-color: #ff8500;
}

button:hover {
  background-color: #ff8500;
}

/* Content section to display the selected option */
.content {
  margin-top: 20px;
  text-align: left;
}

.small-qrcode {
  width: 200px;
  /* Display size */
  height: 200px;
}

/* Footer section */
.footer {
  margin-top: 30px;
}

.footer a {
  color: #07538f;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

a {
  color: #07538f; /* Use a brand color for links */
  text-decoration: underline; /* Add underline to indicate it's a link */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-weight: bold; /* Optional: Make the link stand out */
}

a:hover {
  color: #ff8500; /* Change color on hover for a dynamic effect */
  text-decoration: none; /* Optional: Remove underline on hover */
}
</style>
