import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/global.css";
import MyChelperHeader from "@/components/MyChelperHeader.vue";
import MyChelperNavLogo from "@/components/MyChelperNavLogo.vue";
import MyChelperFooter from "./components/MyChelperFooter.vue";
import SubscriptionDetails from "./components/SubscriptionDetails.vue";
import { createVfm } from "vue-final-modal";
import { createPinia } from "pinia";
import { createMetaManager } from "vue-meta";

const app = createApp(App);

app.component("MyChelperHeader", MyChelperHeader);
app.component("MyChelperNavLogo", MyChelperNavLogo);
app.component("MyChelperFooter", MyChelperFooter);
app.component("SubscriptionDetails", SubscriptionDetails);

const vfm = createVfm();
const pinia = createPinia();
const metaManager = createMetaManager();

app.use(vfm);
app.use(pinia);
app.use(metaManager);
app.use(store).use(router).mount("#app");
