import axios from "axios";

/**
 * Upload an image to Imgur.
 * @param {File} file - The image file to upload.
 * @returns {Object|null} - The uploaded image's URL and delete hash, or null on failure.
 */
export async function uploadImage(file, category, churchId) {
  try {
    const formData = new FormData();
    formData.append("image", file);

    const apiKey = process.env.VUE_APP_MYCHELPER_API_KEY;
    const env = process.env.VUE_APP_ENV;
    const response = await axios.post(
      `https://images.mychelper.com/api/images/upload/${env}/${category}/${churchId}`,
      formData,
      {
        headers: {
          "MYCHELPER-API-KEY": apiKey,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return {
      url: response.data.url,
      deleteHash: response.data.image_path,
    };
  } catch (error) {
    console.error("Failed to upload image:", error.response || error);
    return null;
  }
}

/**
 * Delete an image from Imgur.
 * @param {string} deleteHash - The delete hash of the image.
 */
export async function deleteImage(deleteHash) {
  try {
    const apiKey = process.env.VUE_APP_MYCHELPER_API_KEY;
    await axios.delete(
      `https://images.mychelper.com/api/images/delete/${deleteHash}`,
      {
        headers: {
          "MYCHELPER-API-KEY": apiKey,
        },
      }
    );
  } catch (error) {
    console.error("Failed to delete image:", error.response || error);
  }
}

/**
 * Fetch used storage for a specific environment, category, and church.
 * @param {string} env - The environment (e.g., 'local', 'production', 'sandbox').
 * @param {string} category - The category (e.g., 'home', 'inbox').
 * @param {string} churchId - The ID of the church.
 * @returns {number|null} - The used storage in MB, or null on failure.
 */
export async function fetchStorageUsage(env, category, churchId) {
  try {
    const apiKey = process.env.VUE_APP_MYCHELPER_API_KEY;

    const response = await axios.get(
      `https://images.mychelper.com/api/images/usage/${env}/${category}/${churchId}`,
      {
        headers: {
          "MYCHELPER-API-KEY": apiKey,
        },
      }
    );

    return response.data.totalSizeMB;
  } catch (error) {
    console.error("Failed to fetch storage usage:", error.response || error);
    return null;
  }
}
