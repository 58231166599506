<template>
  <footer class="footer">
    <p>
      &#169; 2020 - {{ currentYear }} <span>my</span
      ><span class="highlighted-brand-color">C</span
      ><span>helper - My Church/Community Helper</span>
    </p>
    <p>
      Discover MyChelper, the free customizable church management app for
      churches of all sizes. Manage events, send notifications, and engage your
      congregation—all in one place.
    </p>
    <p>
      <a href="mailto:care@mychelper.com">Contact Us</a> |
      <a href="/terms">Terms</a> |
      <a href="/privacy">Privacy Policy</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: "myChelperFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(), // Dynamically set the current year
    };
  },
};
</script>

<style scoped>
.footer a {
  color: #ff8500; /* Orange branding color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Optional for emphasis */
  transition: color 0.3s ease; /* Smooth hover effect */
}

.footer a:hover {
  color: #e07600; /* Slightly darker orange for hover */
  text-decoration: underline; /* Optional hover underline */
}
</style>
