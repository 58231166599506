<template>
  <div class="app-home-screen">
    <!-- Header with Buttons -->
    <div class="header">
      <button
        v-if="hasUnsavedChanges"
        @click="resetChanges"
        class="reset-btn"
        title="Revert Changes"
      >
        Reset Changes
      </button>
      <button
        v-if="hasUnsavedChanges"
        @click="publishChanges"
        class="publish-btn"
        title="Out of Sync"
      >
        Publish Changes
      </button>
    </div>
    <div class="header">
      <h3>{{ titleLabel }}</h3>
    </div>
    <div class="header">
      <!-- Back Button for Child Cells -->
      <div v-if="currentParentId !== 0" class="back-button">
        <button @click="goBackToParent" class="common-btn">Back</button>
      </div>
      <button @click="openAddCellForm" class="common-btn">
        {{ addButtonLabel }}
      </button>
    </div>

    <!-- Add/Edit Cell Form -->
    <!-- Add/Edit Cell Form as a Full-Page Overlay -->
    <div
      v-if="isAddingCell || editingCell"
      class="form-overlay"
      @click="closeForm($event)"
    >
      <div class="form-modal" @click.stop>
        <AddEditCellForm
          :is-edit="!!editingCell"
          :initial-cell-data="editingCell || { parentCellId: currentParentId }"
          :is-top-level="currentParentId === 0"
          :churchId="churchId"
          :currentPlan="currentPlan"
          @add-cell="handleAddCell"
          @edit-cell="handleEditCell"
          @cancel="closeForm"
        />
      </div>
    </div>

    <!-- Cells -->
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else class="cell-list">
      <draggable
        v-model="filteredCells"
        group="cells"
        @end="onDragEnd"
        item-key="id"
        :handle="'.drag-handle'"
      >
        <template #item="{ element }">
          <div class="cell-item">
            <!-- Cell Content -->
            <div
              class="cell-content"
              :style="{
                backgroundImage: element.backgroundImageUrl
                  ? `url(${element.backgroundImageUrl})` // Use new backgroundImageUrl if available
                  : element.backgroundImage
                  ? `url(${element.backgroundImage})` // Fallback to the old image
                  : 'none',
                backgroundColor: element.backgroundColor || '#ffffff',
                paddingTop: calculatePaddingTop(element.aspectRatio),
              }"
            >
              <h4
                :style="{ color: element.textColor || '#000000' }"
                class="cell-text"
              >
                {{ element.cellName || "Unnamed Cell" }}
              </h4>
            </div>

            <!-- Cell Actions -->
            <div
              class="cell-actions"
              :class="{ active: element.isActive, inactive: !element.isActive }"
            >
              <!-- Drag Icon -->
              <div class="drag-icon drag-handle">≡</div>

              <!-- Edit Button -->
              <button
                class="action-btn edit-btn"
                @click.stop="openEditModal(element)"
              >
                ✏️
              </button>

              <!-- Manage Children Button -->
              <button
                v-if="element.hasChildCell"
                class="action-btn manage-children-btn"
                @click.stop="handleCellClick(element)"
              >
                📝
              </button>

              <!-- Delete Button -->
              <button
                class="action-btn delete-btn"
                @click.stop="deleteCell(element)"
              >
                ❌
              </button>
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <!-- Acknowledgment Modal -->
    <div v-if="acknowledgmentVisible" class="acknowledgment-modal">
      <div class="modal-content">
        <h3>{{ acknowledgmentMessage }}</h3>
        <!-- Show progress indicator if not the final step -->
        <div v-if="!isFinalStep" class="progress-indicator">
          <span>Processing...</span>
        </div>
        <!-- Show the button only after the last step -->
        <button
          v-if="isFinalStep"
          @click="closeAcknowledgment"
          class="confirm-btn"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import AddEditCellForm from "./AddEditCellForm.vue";
import { uploadImage, deleteImage } from "@/utils/imageService";

export default {
  name: "AppHomeScreen",
  components: { draggable, AddEditCellForm },
  props: {
    churchId: {
      type: Number,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cells: [],
      currentParentId: 0,
      filteredCells: [],
      deletedCells: [],
      dbCells: [],
      editingCell: null,
      isAddingCell: false,
      loading: true,
      error: null,
      statusMessage: "",
      acknowledgmentVisible: false,
      acknowledgmentMessage: "",
      isFinalStep: false,
    };
  },
  computed: {
    currentCells() {
      // Show cells based on the current parent ID
      return this.cells.filter(
        (cell) => cell.parentCellId === this.currentParentId
      );
    },
    hasUnsavedChanges() {
      return JSON.stringify(this.cells) !== JSON.stringify(this.dbCells);
    },
    titleLabel() {
      return this.currentParentId === 0
        ? "Customize Home Screen"
        : "Customize Page";
    },
    addButtonLabel() {
      return this.filteredCells.length === 0
        ? "Click here to add your First Block"
        : "Add New Block";
    },
  },
  watch: {
    currentParentId: {
      immediate: true,
      handler(newParentId) {
        this.updateFilteredCells(newParentId);
      },
    },
  },
  methods: {
    async fetchHomeCells() {
      console.log("fetchHomeCells called");
      try {
        this.loading = true;
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL; // Use your base URL
        const response = await axios.post(
          `${apiBaseUrl}/churches/admin-home`,
          { churchId: this.churchId },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.dbCells = response.data.map((cell) => {
          return {
            ...cell,
            aspectRatio: cell.aspectRatio?.replace(":", " / ") || "16 / 5",
            backgroundImageUrl:
              cell.backgroundImageUrl || cell.backgroundImage || null,
          };
        });

        this.cells = JSON.parse(JSON.stringify(this.dbCells));
        this.updateFilteredCells(this.currentParentId);
      } catch (err) {
        this.error = "Failed to fetch home cells.";
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    updateFilteredCells(parentId) {
      this.filteredCells = this.cells.filter(
        (cell) => cell.parentCellId === parentId
      );
    },
    calculatePaddingTop(aspectRatio) {
      if (!aspectRatio || !aspectRatio.includes("/")) {
        return "calc(100% / (16 / 5))"; // Default to 16 / 5
      }

      const [width, height] = aspectRatio.split("/").map(Number);

      if (isNaN(width) || isNaN(height)) {
        return "calc(100% / (16 / 5))"; // Default to 16 / 5
      }

      return `calc(100% / (${width} / ${height}))`;
    },
    onDragEnd() {
      const updatedCells = this.filteredCells.map((cell, index) => ({
        ...cell,
        cellOrder: index,
      }));
      this.cells = [
        ...this.cells.filter(
          (cell) => cell.parentCellId !== this.currentParentId
        ),
        ...updatedCells,
      ];
      this.markUnsavedChanges();
    },
    markUnsavedChanges() {
      this.$forceUpdate();
    },
    resetChanges() {
      if (confirm("Are you sure you want to reset all changes?")) {
        this.cells = JSON.parse(JSON.stringify(this.dbCells)); // Reset cells to the original state
        this.updateFilteredCells(this.currentParentId); // Update the filtered cells for the current parent view
        this.markUnsavedChanges(); // Force UI to re-render
        this.editedCells = [];
        this.deletedCells = [];
        alert("Changes have been reset!");
      }
    },
    async uploadImage(file, category, churchId) {
      const response = await uploadImage(file, category, churchId);
      if (response) {
        console.log("Image uploaded successfully:", response.url);
        return response; // Return the uploaded image info
      } else {
        console.error("Image upload failed!");
        return null;
      }
    },
    async deleteImage(deleteHash) {
      try {
        await deleteImage(deleteHash);
        console.log("Image deleted successfully.");
      } catch (error) {
        console.error("Failed to delete image:", error);
      }
    },
    async publishChanges() {
      this.showAcknowledgment("Preparing changes..."); // Show initial status
      await this.$nextTick(); // Ensure UI updates

      const parentIdMap = {}; // Map temporary IDs to actual IDs

      // Separate cells into new parent cells, new child cells, and edited cells
      const { newParentCells, newChildCells, editedCells } =
        this.separateCells();

      // Step 1: Add new parent cells
      await this.processNewParentCells(newParentCells, parentIdMap);

      // Step 2: Add new child cells
      await this.processNewChildCells(newChildCells, parentIdMap);

      // Step 3: Handle edited cells
      await this.processEditedCells(editedCells);

      // Step 4: Handle deleted cells
      await this.processDeletedCells();

      // Finalize
      this.showAcknowledgment("Finalizing changes...");
      await this.$nextTick(); // Ensure UI updates
      this.dbCells = JSON.parse(JSON.stringify(this.cells));
      this.showAcknowledgment("Changes published successfully!", true);

      this.fetchHomeCells();
    },

    separateCells() {
      const newParentCells = [];
      const newChildCells = [];
      const editedCells = [];

      for (const cell of this.cells) {
        const existingCell = this.dbCells.find(
          (dbCell) => dbCell.id === cell.id
        );

        // New cells
        if (!existingCell && !cell.parentCellId) {
          newParentCells.push(cell);
        } else if (!existingCell && cell.parentCellId) {
          newChildCells.push(cell);
        } else if (
          existingCell &&
          JSON.stringify(existingCell) !== JSON.stringify(cell)
        ) {
          // Edited cells
          editedCells.push(cell);
        }
      }

      return { newParentCells, newChildCells, editedCells };
    },

    async processNewParentCells(newParentCells, parentIdMap) {
      for (const parentCell of newParentCells) {
        try {
          this.showAcknowledgment(
            `Uploading parent cell: ${parentCell.cellName || "Unnamed Cell"}...`
          );
          await this.$nextTick(); // Ensure UI updates

          // Handle image upload
          const { backgroundImageUrl, backgroundImageDeleteHash } =
            await this.handleImageUpload(parentCell);

          const formattedAspectRatio = parentCell.aspectRatio.replace(
            ":",
            " / "
          );

          const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/churches/admin-add-parent-cell`,
            {
              ...parentCell,
              churchId: this.churchId,
              cellName: parentCell.cellName || "",
              url: parentCell.url == "https://" ? null : parentCell.url,
              hasChildCell: !!parentCell.hasChildCell,
              isActive: !!parentCell.isActive,
              cellOrder: Number(parentCell.cellOrder || 0),
              openInBrowser: parentCell.openInBrowser || false,
              aspectRatio: formattedAspectRatio,
              backgroundImageUrl,
              backgroundImageDeleteHash,
            },
            {
              headers: { Authorization: `Bearer ${this.token}` },
            }
          );

          // Map the temporary ID to the actual ID
          parentIdMap[parentCell.id] = response.data.parentCellId;
        } catch (error) {
          this.showAcknowledgment(
            `Error adding parent cell: ${
              parentCell.cellName || "Unnamed Cell"
            }. Skipping...`
          );
          await this.$nextTick(); // Ensure UI updates
          console.error(error);
          continue; // Continue processing other cells
        }
      }
    },

    async processNewChildCells(newChildCells, parentIdMap) {
      if (newChildCells.length > 0) {
        this.showAcknowledgment("Uploading new child cells...");
        await this.$nextTick(); // Ensure UI updates

        const cellsToAdd = [];
        for (const childCell of newChildCells) {
          try {
            // Handle image upload
            const { backgroundImageUrl, backgroundImageDeleteHash } =
              await this.handleImageUpload(childCell);

            const formattedAspectRatio = childCell.aspectRatio.replace(
              ":",
              " / "
            );

            // Update parentCellId if it was a new parent cell
            const parentCellId =
              parentIdMap[childCell.parentCellId] || childCell.parentCellId;

            cellsToAdd.push({
              ...childCell,
              churchId: this.churchId,
              cellName: childCell.cellName,
              parentCellId: parentCellId,
              url: childCell.url == "https://" ? null : childCell.url,
              hasChildCell: !!childCell.hasChildCell,
              isActive: !!childCell.isActive,
              cellOrder: Number(childCell.cellOrder || 0),
              openInBrowser: childCell.openInBrowser || false,
              aspectRatio: formattedAspectRatio,
              backgroundImageUrl,
              backgroundImageDeleteHash,
            });
          } catch (error) {
            this.showAcknowledgment(
              `Error processing child cell: ${
                childCell.cellName || "Unnamed Cell"
              }. Skipping...`
            );
            await this.$nextTick(); // Ensure UI updates
            console.error(error);
            continue; // Continue processing other cells
          }
        }

        // Send child cells to the server
        await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/churches/admin-add-home-cells`,
          { cells: cellsToAdd },
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
      }
    },

    async processEditedCells(editedCells) {
      if (editedCells.length > 0) {
        this.showAcknowledgment("Sending edited cells to the server...");
        await this.$nextTick(); // Ensure UI updates

        const cellsToEdit = [];
        for (const cell of editedCells) {
          try {
            // Handle image upload
            const { backgroundImageUrl, backgroundImageDeleteHash } =
              await this.handleImageUpload(cell, true);

            const formattedAspectRatio = cell.aspectRatio.replace(":", " / ");

            cellsToEdit.push({
              ...cell,
              cellName: cell.cellName,
              hasChildCell: !!cell.hasChildCell,
              isActive: !!cell.isActive,
              url: cell.url == "https://" ? null : cell.url,
              cellOrder: Number(cell.cellOrder),
              aspectRatio: formattedAspectRatio,
              backgroundImageUrl,
              backgroundImageDeleteHash,
            });
          } catch (error) {
            this.showAcknowledgment(
              `Error updating cell: ${
                cell.cellName || "Unnamed Cell"
              }. Skipping...`
            );
            await this.$nextTick(); // Ensure UI updates
            console.error(error);
            continue; // Continue processing other cells
          }
        }

        await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/churches/admin-edit-home-cells`,
          { cells: cellsToEdit },
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
      }
    },

    async processDeletedCells() {
      if (this.deletedCells.length > 0) {
        this.showAcknowledgment("Deleting cells from the server...");
        await this.$nextTick();

        const deletedCellsImageHashids = this.deletedCells
          .filter((cell) => cell.backgroundImageDeleteHash)
          .map((cell) => cell.backgroundImageDeleteHash);

        for (const id of deletedCellsImageHashids) {
          await this.deleteImage(id);
        }

        const deletedCellIds = this.deletedCells.map((cell) => cell.id);
        await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/churches/admin-delete-home-cells`,
          { cellIds: deletedCellIds },
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );

        this.deletedCells = [];
      }
    },

    async handleImageUpload(cell, isEdit = false) {
      let backgroundImageUrl = cell.backgroundImageUrl || null;
      let backgroundImageDeleteHash = cell.backgroundImageDeleteHash || null;

      if (cell.imageFile) {
        // If editing, delete old image if exists
        if (isEdit && cell.backgroundImageDeleteHash) {
          await this.deleteImage(cell.backgroundImageDeleteHash);
        }
        const imageResponse = await this.uploadImage(
          cell.imageFile,
          "home",
          this.churchId
        );
        if (imageResponse) {
          backgroundImageUrl = imageResponse.url;
          backgroundImageDeleteHash = imageResponse.deleteHash;
        }
      }

      return { backgroundImageUrl, backgroundImageDeleteHash };
    },
    showAcknowledgment(message, isFinalStep = false) {
      this.acknowledgmentMessage = ""; // Clear any existing message
      this.isFinalStep = isFinalStep; // Set whether this is the final step
      this.acknowledgmentVisible = true; // Show the modal
      this.$nextTick(() => {
        this.acknowledgmentMessage = message;
      });
    },
    closeAcknowledgment() {
      this.acknowledgmentVisible = false;
      this.acknowledgmentMessage = ""; // Clear the message
      this.isFinalStep = false; // Reset final step flag
    },
    openAddCellForm() {
      this.isAddingCell = true;
      this.editingCell = null; // Clear editing state
    },
    openEditModal(cell) {
      this.isAddingCell = false; // Clear adding state
      this.editingCell = { ...cell };
    },
    // Mock background image path generator for testing
    mockImagePath(cellId) {
      return `https://via.placeholder.com/800x400?text=Cell+${cellId}`;
    },
    handleAddCell(newCell) {
      const hasImage = !!newCell.backgroundImage; // Check if a background image is present

      newCell.id = Date.now(); // Temporary ID
      this.cells.push({
        ...newCell,
        aspectRatio: newCell.aspectRatio || "16 / 5", // Default to 16:5
        hasBackgroundImage: hasImage, // Use the image preview if available
      });

      this.updateFilteredCells(this.currentParentId);
      this.markUnsavedChanges();
      this.closeForm();
    },
    handleEditCell(updatedCell) {
      const index = this.cells.findIndex((cell) => cell.id === updatedCell.id);
      if (index !== -1) {
        const hasImage = !!updatedCell.backgroundImageUrl;

        this.cells.splice(index, 1, {
          ...updatedCell,
          aspectRatio: updatedCell.aspectRatio || "16 / 5",
          hasBackgroundImage: hasImage,
          backgroundImageUrl:
            updatedCell.backgroundImageUrl ||
            this.cells[index].backgroundImageUrl,
        });

        this.updateFilteredCells(this.currentParentId);
        this.calculatePaddingTop(this.aspectRatio);
        this.markUnsavedChanges();
      }
      this.closeForm();
    },
    closeForm(event) {
      if (!event || event?.target?.classList?.contains("form-overlay")) {
        this.isAddingCell = false;
        this.editingCell = null;
      }
    },
    deleteCell(cell) {
      if (!confirm(`Delete "${cell.cellName || "Unnamed Cell"}"?`)) return;
      this.cells = this.cells.filter((c) => c.id !== cell.id);
      this.updateFilteredCells(this.currentParentId);
      this.deletedCells.push(cell);
      this.markUnsavedChanges();
    },
    handleCellClick(cell) {
      if (cell.hasChildCell) this.currentParentId = cell.id;
    },
    goBackToParent() {
      this.currentParentId = 0;
    },
    getCellBackgroundImage(cellId) {
      return `https://api.mychelper.com/MCYLive/Images/ChurchHomeImages/${this.churchId}/${cellId}.png`;
    },
  },
  mounted() {
    this.fetchHomeCells();
  },
};
</script>

<style scoped>
/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Container Constraint */
.app-home-screen {
  max-width: 400px;
  margin: 0 auto;
}

.publish-btn {
  background-color: #ffc107; /* Yellow background */
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000; /* Black text */
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.publish-btn:hover {
  background-color: #e0a800; /* Darker yellow on hover */
}

.publish-btn:active {
  background-color: #c69500; /* Even darker yellow when clicked */
}

/* Cell List */
.cell-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Cell Item */
.cell-item {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0; /* Remove padding to ensure the image fits properly */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden; /* Ensure content doesn't overflow the cell */
  min-height: 150px;
}

/* Cell Content */
.cell-content {
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
  position: relative; /* For absolute positioning of the text */
  width: 100%;
  padding-top: calc(100% / (16 / 5)); /* Fallback for 16:5 */
}

.cell-text {
  position: absolute;
  bottom: 16px;
  left: 16px;
  margin: 0;
  font-size: 1rem; /* Adjust font size */
  font-weight: bold;
  color: inherit; /* Inherit dynamic color */
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3); /* Add shadow for readability */
}

/* Cell Actions */
.cell-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 5px;
}

/* Active Cell Actions */
.cell-actions.active {
  background-color: #d4edda; /* Light green for active */
  border-left: 3px solid #28a745; /* Green border for emphasis */
}

/* Inactive Cell Actions */
.cell-actions.inactive {
  background-color: #f8d7da; /* Light red for inactive */
  border-left: 3px solid #dc3545; /* Red border for emphasis */
}

/* Drag Icon */
.drag-icon {
  cursor: grab;
  font-size: 18px;
}

.drag-handle {
  cursor: grab; /* Indicate that the icon can be dragged */
  font-size: 16px;
  color: #666; /* Neutral color */
  padding: 5px;
}

.drag-handle:hover {
  color: #333; /* Darker color on hover */
}

/* Action Buttons */
.action-btn {
  border: none;
  background: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.edit-btn {
  color: #007bff;
}

.manage-children-btn {
  color: #28a745;
}

.delete-btn {
  color: #dc3545;
}

.action-btn:hover {
  text-decoration: underline;
}

.reset-btn {
  background-color: #dc3545; /* Red background for reset */
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 1rem; /* Add spacing between buttons */
}

.reset-btn:hover {
  background-color: #c82333; /* Darker red on hover */
}

.reset-btn:active {
  background-color: #bd2130; /* Even darker red when clicked */
}

.acknowledgment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.confirm-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #28a745; /* Green */
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: #218838; /* Darker green */
}

/* Full-page overlay for the form */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Ensure it spans the full width */
  height: 100%; /* Ensure it spans the full height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Modal container for the form */
.form-modal {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: calc(100% - 1rem); /* Ensure the modal fits within the screen */
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
  box-sizing: border-box;
}

/* Input fields within the form */
.form-modal input,
.form-modal select,
.form-modal textarea {
  width: 100%;
  box-sizing: border-box;
}

/* Small screen adjustments */
@media (max-width: 600px) {
  .form-modal {
    padding: 1rem;
    width: calc(100% - 1rem); /* Reduce width for better fit on small screens */
    margin: 0.5rem; /* Add margin to avoid touching screen edges */
  }
}
</style>
