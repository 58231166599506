<template>
  <div class="why-mychelper-section">
    <div class="why-mychelper-container">
      <div class="why-mychelper-text">
        <p>
          <b>{{ currentFeature.title }}</b>
        </p>
        <p>{{ currentFeature.description }}</p>
        <ul>
          <li v-for="(detail, index) in currentFeature.details" :key="index">
            <b>{{ detail.title }}:</b> {{ detail.content }}
          </li>
        </ul>
      </div>
      <div class="why-mychelper-image">
        <img :src="currentFeature.image" :alt="currentFeature.alt" />
      </div>
    </div>

    <!-- Pagination Controls -->
    <div class="pagination-controls">
      <button
        class="page-btn"
        :disabled="currentPage === 0"
        @click="previousPage"
      >
        Previous
      </button>
      <button
        class="page-btn"
        :disabled="currentPage === features.length - 1"
        @click="nextPage"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "MyChelper Features | Explore Customizable Church Tools",
    meta: [
      {
        name: "description",
        content:
          "Explore MyChelper features such as MyChurch, Inbox, and MyCommunity. Learn how to manage church activities, enhance engagement, and grow together.",
      },
      {
        property: "og:title",
        content: "MyChelper Features | Explore Customizable Church Tools",
      },
      {
        property: "og:description",
        content:
          "Discover MyChelper's customizable tools for churches and communities, including MyChurch, Inbox, and MyCommunity.",
      },
      {
        property: "og:image",
        content: "https://www.mychelper.com/assets/myChelperHome.jpeg",
      },
    ],
  },
  data() {
    return {
      currentPage: 0, // Tracks the current page
      features: [
        {
          title:
            "MyChelper is 100% Free and 100% Customizable, designed to help churches effectively manage their activities and enhance community engagement.",
          description:
            "The MyChelper Home Screen is designed with flexibility at its core. Churches and communities can adapt every aspect to reflect their unique identity.",
          details: [
            {
              title: "Image Customization",
              content:
                "Upload your own images to create a visually appealing home screen.",
            },
            {
              title: "Text Customization",
              content:
                "Adjust text colors to align with your branding and message.",
            },
            {
              title: "Background Customization",
              content:
                "Personalize the background color or add custom images to create a welcoming atmosphere that resonates with your audience",
            },
            {
              title: "Aspect Ratios",
              content:
                "Select the aspect ratio that best fits Your mages, or text blocks ensuring visually appealing blocks of varying sizes that perfectly complement the content they choose to highlight.",
            },
            {
              title: "Dynamic Layouts",
              content:
                "Add and rearrange content sections such as Sermons, Events, and Resources with ease, tailoring the experience to fit your church’s needs.",
            },
          ],
          image: require("@/assets/myChelperHome.png"),
          alt: "MyChurch Illustration",
        },
        {
          title: "The Inbox includes messages from Your Church only",
          description:
            "Keep your church congregation connected with important messages and updates from your church.",
          details: [
            {
              title: "In-App Push Notifications",
              content:
                "All Inbox Messages are accompanied bt instant push notifications to instantly notify your members. ",
            },
            { title: "Engagement", content: "Boost member communication." },
            {
              title: "Message Archive",
              content:
                "Only Admins with inbox access can access past messages anytime.",
            },
            {
              title: "3 different types of messages",
              content:
                "You can send 3 diffent types of messages (Text, URLs, and Images).",
            },
            {
              title: "Message Archive",
              content:
                "Only Admins with inbox access can access past messages anytime.",
            },
          ],
          image: require("@/assets/myChelperInbox.jpeg"),
          alt: "Inbox Illustration",
        },
        {
          title: "My Community",
          description:
            "Community is essential to our Christian faith. My Community is designed to help churches and communities foster a sense of belonging and togetherness.",
          details: [
            {
              title: "Prayer Board",
              content:
                "As community of believers we are called to pray for each other.",
            },
            {
              title: "Event Management",
              content:
                "Organize and promote events effortlessly, Add your service times and events out of the notmal schedule and reach out to all your church congregation.",
            },
            {
              title: "Community Engagement",
              content: "We are committed to bringing people closer together.",
            },
            {
              title: "More to come",
              content:
                "We are committed to bring more features that grows and connect the community bringing them closer to God.",
            },
            {
              title: "It's All optional",
              content:
                "All the upcoming My Community Features will be optional and can be turned off if you don't want to use them.",
            },
          ],
          image: require("@/assets/MyChelperCommunity.jpeg"),
          alt: "MyCommunity Illustration",
        },
      ],
    };
  },
  computed: {
    currentFeature() {
      return this.features[this.currentPage];
    },
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.features.length - 1) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
  },
};
</script>

<style scoped>
.why-mychelper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: left;
}

.why-mychelper-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.why-mychelper-image {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-mychelper-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-btn {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #07538f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.page-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .why-mychelper-container {
    flex-direction: column;
    row-gap: 20px;
    padding: 0 1rem;
  }

  .why-mychelper-text,
  .why-mychelper-image {
    flex: none;
    width: 100%;
    margin-right: 0;
  }
}
</style>
