<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <h2>{{ event ? "Edit Event" : "Add Event" }}</h2>
      <div class="scrollable-form">
        <form @submit.prevent="saveEvent">
          <!-- Event Name -->
          <div class="form-group">
            <label>Event Name:</label>
            <input
              v-model="form.eventName"
              placeholder="Enter event name"
              required
            />
          </div>

          <!-- Event Description -->
          <div class="form-group">
            <label>Description:</label>
            <textarea
              v-model="form.eventDescription"
              placeholder="Enter event description"
              required
            ></textarea>
          </div>

          <!-- Event Location -->
          <div class="form-group">
            <label>Location:</label>
            <input
              v-model="form.eventLocation"
              placeholder="Enter event location"
            />
          </div>

          <!-- Start Date & Time -->
          <div class="form-group">
            <label>Start Date:</label>
            <input type="date" v-model="form.startDate" required />
          </div>
          <div class="form-group">
            <label>Start Time:</label>
            <input type="time" v-model="form.startTime" />
          </div>

          <!-- End Date & Time -->
          <div class="form-group">
            <label>End Date:</label>
            <input type="date" v-model="form.endDate" />
          </div>
          <div class="form-group">
            <label>End Time:</label>
            <input type="time" v-model="form.endTime" />
          </div>

          <!-- Checkboxes -->
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.isAllDayEvent" /> Is All Day
              Event
            </label>
          </div>
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.isLivestreamEvent" /> Has
              Livestream Option
            </label>
          </div>
          <div v-if="form.isLivestreamEvent" class="form-group">
            <label>Livestream URL:</label>
            <input
              v-model="form.livestreamURL"
              type="url"
              placeholder="Enter livestream URL"
            />
          </div>
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.isPublic" /> Is Public Event
            </label>
          </div>
          <div class="form-group">
            <label for="timeZone">Time Zone:</label>
            <select id="timeZone" v-model="form.timeZone" required>
              <option value="" disabled>Select Time Zone</option>
              <option
                v-for="zone in timeZones"
                :key="zone.value"
                :value="zone.value"
              >
                {{ zone.label }}
              </option>
            </select>
          </div>

          <!-- TODO: This will be added when adding curch managment in the future -->
          <!-- <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.isServingGroupEvent" /> Is
              Serving Group Event
            </label>
          </div>
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.isGroupEvent" /> Is Group
              Event
            </label>
          </div>
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.isRSVP" /> Is RSVP Required
            </label>
          </div> -->

          <!-- Recurring Event Fields -->
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.isRecurring" /> Is Recurring
              Event
            </label>
          </div>
          <!-- Recurring Fields -->
          <div v-if="form.isRecurring" class="form-recurring">
            <div class="form-group">
              <label>Recurring Type:</label>
              <select
                v-model="form.recurringTypeId"
                @change="form.recurringTypeId = Number(form.recurringTypeId)"
                required
              >
                <option value="" disabled>Select Recurring Type</option>
                <option value="1">Daily</option>
                <option value="2">Weekly</option>
                <option value="3">Monthly</option>
                <option value="4">Yearly</option>
              </select>
            </div>

            <!-- Separation Count -->
            <div class="form-group">
              <label>Occurs every:</label>
              <input type="number" v-model="form.separationCount" min="1" />
              <small class="help-text">{{ separationHelpText }}</small>
            </div>

            <!-- Fields for Weekly Recurrence -->
            <div v-if="form.recurringTypeId === 2" class="form-group">
              <label for="dayOfWeek">Day of Week:</label>
              <select
                id="dayOfWeek"
                v-model="form.dayOfWeek"
                @change="form.dayOfWeek = Number(form.dayOfWeek)"
                required
              >
                <option value="" disabled>Select Day</option>
                <option
                  v-for="(day, index) in daysOfWeek"
                  :key="index"
                  :value="index"
                >
                  {{ day }}
                </option>
              </select>
              <small class="help-text"
                >Select the day for the recurring event.</small
              >
            </div>

            <!-- Fields for Monthly Recurrence -->
            <div v-if="form.recurringTypeId === 3" class="form-group">
              <label>Day of Month:</label>
              <input type="number" v-model="form.dayOfMonth" min="1" max="31" />
              <small class="help-text">Enter a value between 1 and 31.</small>
            </div>

            <!-- Fields for Yearly Recurrence -->
            <div v-if="form.recurringTypeId === 4">
              <div class="form-group">
                <label>Day of Month:</label>
                <input
                  type="number"
                  v-model="form.dayOfMonth"
                  min="1"
                  max="31"
                />
                <small class="help-text">Enter a value between 1 and 31.</small>
              </div>
              <div class="form-group">
                <label>Month of Year:</label>
                <input
                  type="number"
                  v-model="form.monthOfYear"
                  min="1"
                  max="12"
                />
                <small class="help-text"
                  >Enter a value between 1 (January) and 12 (December).</small
                >
              </div>
            </div>

            <!-- Recurring End Date -->
            <div class="form-group">
              <label>Recurring End Date:</label>
              <input type="date" v-model="form.recurringEndDate" />
            </div>
          </div>

          <!-- Form Actions -->
          <div class="form-actions">
            <button
              type="button"
              class="btn btn-secondary"
              @click="$emit('close')"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { USER_ID, TOKEN } from "@/constants/storageKeys";
import axios from "axios";

const userId = localStorage.getItem(USER_ID);

export default {
  props: {
    event: {
      type: Object,
      default: null,
    },
    churchId: {
      type: Number,
      required: true, // Ensure it is mandatory
    },
  },
  data() {
    return {
      form: {
        eventName: this.event?.eventName || "",
        eventDescription: this.event?.eventDescription || "",
        eventLocation: this.event?.eventLocation || "",
        startDate: this.event?.startDate
          ? this.event.startDate.split("T")[0]
          : "",
        startTime: this.event?.startTime || "",
        endDate: this.event?.endDate ? this.event.endDate.split("T")[0] : "",
        endTime: this.event?.endTime || "",
        isAllDayEvent: this.event?.isAllDayEvent || false,
        isLivestreamEvent: this.event?.isLivestreamEvent || false,
        livestreamURL: this.event?.livestreamURL || "",
        isPublic: this.event?.isPublic || false,
        timeZone: this.event?.timeZone || "",
        isServingGroupEvent: this.event?.isServingGroupEvent || false,
        isGroupEvent: this.event?.isGroupEvent || false,
        isRSVP: this.event?.isRSVP || false,
        isRecurring: this.event?.isRecurring || false,
        recurringTypeId:
          this.event?.event_recurring_pattern?.recurringTypeId || 0,
        separationCount:
          this.event?.event_recurring_pattern?.separationCount || 1,
        recurringEndDate: this.event?.event_recurring_pattern?.recurringEndDate
          ? this.event?.event_recurring_pattern?.recurringEndDate.split("T")[0]
          : "",
        dayOfWeek: this.event?.event_recurring_pattern?.dayOfWeek || null,
        weekOfMonth: this.event?.event_recurring_pattern?.weekOfMonth || null,
        dayOfMonth: this.event?.event_recurring_pattern?.dayOfMonth || null,
        monthOfYear: this.event?.event_recurring_pattern?.monthOfYear || null,
        createdBy: userId || "",
        churchId: this.churchId,
      },
      timeZones: [
        { value: "EST", label: "Eastern Standard Time" },
        { value: "CST", label: "Central Standard Time" },
        { value: "MST", label: "Mountain Standard Time" },
        { value: "PST", label: "Pacific Standard Time" },
        { value: "AKST", label: "Alaska Standard Time" },
        { value: "HST", label: "Hawaii-Aleutian Standard Time" },
      ],
      daysOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },
  computed: {
    separationHelpText() {
      switch (this.form.recurringTypeId) {
        case "1": // Daily
          return this.form.separationCount === 1
            ? "Occurs every day."
            : `Occurs every ${this.form.separationCount} days.`;
        case "2": // Weekly
          return this.form.separationCount === 1
            ? "Occurs every week."
            : `Occurs every ${this.form.separationCount} weeks.`;
        case "3": // Monthly
          return this.form.separationCount === 1
            ? "Occurs every month."
            : `Occurs every ${this.form.separationCount} months.`;
        case "4": // Yearly
          return this.form.separationCount === 1
            ? "Occurs every year."
            : `Occurs every ${this.form.separationCount} years.`;
        default:
          return "Define the interval between occurrences.";
      }
    },
  },
  watch: {
    "form.startDate"(newStartDate) {
      if (!this.form.endDate || this.form.endDate < newStartDate) {
        this.form.endDate = newStartDate;
      }
    },
    "form.startTime"(newStartTime) {
      if (!this.form.endTime) {
        const [hours, minutes] = newStartTime.split(":").map(Number);
        const endTime = new Date();
        endTime.setHours(hours + 1); // Add 1 hour
        endTime.setMinutes(minutes);
        this.form.endTime = endTime.toTimeString().split(" ")[0].slice(0, 5); // Format as HH:mm
      }
    },
  },
  methods: {
    async saveEvent() {
      const token = localStorage.getItem(TOKEN);
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        if (this.event) {
          await axios.put(`${apiBaseUrl}/events/${this.event.id}`, this.form, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
        } else {
          await axios.post(`${apiBaseUrl}/events`, this.form, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
        }
        this.$emit("saved");
        this.$emit("close");
      } catch (error) {
        console.error("Error saving event:", error);
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 100%; /* Take full width of parent */
  max-width: 600px; /* Prevent modal from being too wide */
  max-height: 90vh; /* Limit height */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  box-sizing: border-box; /* Include padding in width */
}

.scrollable-form {
  overflow-y: auto;
  padding: 1rem;
  max-height: 70vh; /* Scrollable form within modal */
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

/* Checkbox alignment: inline with text */
.form-group-inline {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between checkbox and label */
  margin-bottom: 1rem;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input,
textarea,
select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #07538f;
  color: white;
}

.btn-secondary {
  background-color: #ff8500;
  color: white;
}

* {
  box-sizing: border-box;
}
</style>
