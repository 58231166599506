export function formatDate(isoDate) {
  if (!isoDate) return null;
  const date = new Date(isoDate);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formatTime(isoTime) {
  if (!isoTime) return null;
  const time = new Date(`1970-01-01T${isoTime}`);
  return time.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatRecurringPattern(pattern) {
  if (!pattern) return "No recurring pattern specified";

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const frequencies = {
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
    4: "Yearly",
  };

  const frequency = frequencies[pattern.recurringTypeId] || "Unknown frequency";

  // Handle Weekly Patterns
  if (pattern.recurringTypeId === 2 && pattern.dayOfWeek !== undefined) {
    const dayOfWeek = days[pattern.dayOfWeek];
    return `${frequency} on ${dayOfWeek}`;
  }

  // Handle Monthly Patterns
  if (pattern.recurringTypeId === 3) {
    if (pattern.dayOfMonth > 0) {
      return `${frequency} on the ${pattern.dayOfMonth}${this.getOrdinalSuffix(
        pattern.dayOfMonth
      )} of the month`;
    }
    if (pattern.weekOfMonth > 0 && pattern.dayOfWeek !== undefined) {
      const weekMapping = ["First", "Second", "Third", "Fourth", "Last"];
      const week = weekMapping[pattern.weekOfMonth - 1] || "Unknown week";
      const dayOfWeek = days[pattern.dayOfWeek];
      return `${frequency} on the ${week} ${dayOfWeek} of the month`;
    }
  }

  // Handle Yearly Patterns
  if (pattern.recurringTypeId === 4 && pattern.monthOfYear > 0) {
    const date = new Date(
      2024,
      pattern.monthOfYear - 1,
      pattern.dayOfMonth || 1
    ); // Default day to 1
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });
    return `${frequency} on ${formattedDate}`;
  }

  // Default Frequency Description
  return frequency;
}

export function getOrdinalSuffix(day) {
  const j = day % 10,
    k = day % 100;
  if (j === 1 && k !== 11) return "st";
  if (j === 2 && k !== 12) return "nd";
  if (j === 3 && k !== 13) return "rd";
  return "th";
}
