<template>
  <div class="subscription-container">
    <h2>Active Subscriptions</h2>
    <div class="subscription-details">
      <div class="billing-info">
        <strong
          >Plan:
          <span class="brand-color">{{
            subscription?.stripeProductName || "Free Plan"
          }}</span></strong
        >
        <span class="next-bill"
          >Billing ${{ (subscription?.amount / 100).toFixed(2) }}</span
        >
        <span class="next-bill">Next bill: {{ formattedRenewalDate }}</span>
      </div>
      <div class="plan-info">
        <span>{{ prompt }}</span>
        <button class="upgrade-btn" @click="$emit('action')">
          {{ buttonTitle }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useSubscriptionStore } from "@/stores/subscription";

export default {
  name: "SubscriptionDetails",
  props: {
    prompt: {
      type: String,
      default: "Want a Branded App?",
    },
    buttonTitle: {
      type: String,
      default: "Close",
    },
  },
  computed: {
    subscription() {
      const subscriptionStore = useSubscriptionStore();
      return subscriptionStore.subscription;
    },
    formattedRenewalDate() {
      if (this.subscription?.renewalDate) {
        const date = new Date(this.subscription.renewalDate);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
      return "N/A";
    },
  },
};
</script>

<style scoped>
.subscription-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.subscription-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billing-info {
  font-size: 1rem;
}

.next-bill {
  display: block;
  font-size: 0.9rem;
  color: #666;
}

.plan-info {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  gap: 10px; /* Add spacing between elements */
}

.plan-info span {
  font-size: 1rem;
  color: #333;
  text-align: center; /* Center align the text */
}

.upgrade-btn {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upgrade-btn:hover {
  background-color: #0056b3;
}
</style>
