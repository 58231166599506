<template>
  <div class="mychelper-header">
    <h1 class="logo">
      <span>my</span><span class="highlighted-brand-color">C</span
      ><span>helper</span>
    </h1>
    <small class="tagline">My Church/Community Helper</small>
  </div>
</template>

<script>
export default {
  name: "myChelperHeader",
};
</script>

<style scoped>
.mychelper-header {
  text-align: center;
  margin: 10px;
}

.logo {
  font-family: Arial, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.tagline {
  font-size: 1rem;
  color: #ff8500; /* Branding Orange */
  font-family: Arial, sans-serif;
}
</style>
