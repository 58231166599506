<template>
  <div class="admins-component">
    <h1>Admins Management</h1>

    <!-- Button to Open Modal -->
    <button @click="openAddAdminModal" class="add-admin-btn">Add Admin</button>

    <!-- Admin List -->
    <div v-if="admins.length">
      <h2>Current Admins</h2>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Access</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="admin in admins" :key="admin.id">
            <td>
              {{ admin.app_user.firstName }} {{ admin.app_user.lastName }}
            </td>
            <td>
              <ul>
                <li v-if="admin.homeScreenAccess">App Home Screen</li>
                <li v-if="admin.broadcastAccess">Mass Notifications</li>
                <li v-if="admin.publicEventsAccess">Events</li>
                <li v-if="admin.adminsAccess">Admins</li>
              </ul>
            </td>
            <td>
              <button @click="editAdmin(admin)">Edit</button>
              <button @click="deleteAdmin(admin.userId)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else>No admins found.</p>

    <!-- Add Admin Modal -->
    <VueFinalModal
      v-model="showAddAdminModal"
      content-class="vfm-center-small-modal"
    >
      <template #default>
        <form
          @submit.prevent="isEditMode ? handleUpdateAdmin() : handleAddAdmin()"
          class="add-admin-form"
        >
          <h2>{{ isEditMode ? "Edit Admin" : "Add Admin" }}</h2>

          <input v-if="isEditMode" type="hidden" v-model="admin.userId" />
          <div class="form-group" style="width: 333px; align-self: center">
            <label v-if="isEditMode" for="name">Name:</label>
            <input
              v-if="isEditMode"
              type="text"
              id="name"
              :value="`${admin.app_user.firstName} ${admin.app_user.lastName}`"
              disabled
            />
            <label v-else for="email">Email:</label>
            <input
              v-if="!isEditMode"
              type="email"
              id="email"
              v-model="admin.email"
              required
            />
          </div>

          <div class="form-group checkbox-group">
            <label>
              App Home Screen Access
              <input type="checkbox" v-model="admin.homeScreenAccess" />
            </label>
          </div>

          <div class="form-group checkbox-group">
            <label>
              Mass Notifications Access
              <input type="checkbox" v-model="admin.broadcastAccess" />
            </label>
          </div>

          <div class="form-group checkbox-group">
            <label>
              Events Access
              <input type="checkbox" v-model="admin.publicEventsAccess" />
            </label>
          </div>

          <div class="form-group checkbox-group">
            <label>
              Admins Access
              <input type="checkbox" v-model="admin.adminsAccess" />
            </label>
          </div>

          <!-- Action Buttons -->
          <div class="form-actions">
            <button
              type="button"
              @click="showAddAdminModal = false"
              class="cancel-btn"
            >
              Cancel
            </button>
            <button type="submit" class="submit-btn">
              {{ isEditMode ? "Save Changes" : "Add Admin" }}
            </button>
          </div>
        </form>
      </template>
    </VueFinalModal>
  </div>
</template>

<script>
import axios from "axios";
import { VueFinalModal } from "vue-final-modal";

export default {
  name: "AdminsComponent",
  data() {
    return {
      admins: [], // Stores the list of admins
      admin: {
        adminId: "",
        name: "",
        email: "",
        homeScreenAccess: false,
        broadcastAccess: false,
        publicEventsAccess: false,
        adminsAccess: false,
      },
      isEditMode: false,
      showAddAdminModal: false,
    };
  },
  props: {
    churchId: {
      type: Number,
      required: true,
    },
  },
  components: {
    VueFinalModal,
  },
  methods: {
    // Fetches existing admins
    async getAdmins() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.post(
          `${apiBaseUrl}/churches/get-admins`,
          {
            churchId: this.churchId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.admins = response.data;
      } catch (error) {
        console.error("Error fetching admins:", error);
      }
    },
    // Adds a new admin
    async handleAddAdmin() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.post(
          `${apiBaseUrl}/churches/add-admin`,
          {
            churchId: this.churchId,
            adminEmail: this.admin.email,
            homeScreenAccess: this.admin.homeScreenAccess,
            broadcastAccess: this.admin.broadcastAccess,
            publicEventsAccess: this.admin.publicEventsAccess,
            adminsAccess: this.admin.adminsAccess,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          this.getAdmins();
        }
        this.admin = {
          name: "",
          email: "",
          homeScreenAccess: false,
          broadcastAccess: false,
          churchPeopleAccess: false,
          groupsAccess: false,
          servingGroupsAccess: false,
          publicEventsAccess: false,
          adminsAccess: false,
        };
        this.showAddAdminModal = false; // Close modal
      } catch (error) {
        console.error("Error adding admin:", error);
        alert(
          `Error: ${error.response.data.message}\n${error.response.data.error}`
        );
      }
    },
    // Updates an existing admin
    async handleUpdateAdmin() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.put(
          `${apiBaseUrl}/churches/update-admin`,
          {
            adminId: this.admin.userId,
            homeScreenAccess: this.admin.homeScreenAccess,
            broadcastAccess: this.admin.broadcastAccess,
            publicEventsAccess: this.admin.publicEventsAccess,
            adminsAccess: this.admin.adminsAccess,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          this.getAdmins();
        }
        this.showAddAdminModal = false; // Close modal
      } catch (error) {
        console.error("Error updating admin:", error);
        alert(
          `Error: ${error.response.data.message}\n${error.response.data.error}`
        );
      }
    },

    // Deletes an admin
    async deleteAdmin(adminId) {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.delete(
          `${apiBaseUrl}/churches/delete-admin`,
          {
            data: {
              adminId: adminId,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          this.getAdmins();
        }
      } catch (error) {
        console.error("Error deleting admin:", error);
        alert(
          `Error: ${error.response.data.message}\n${error.response.data.error}`
        );
      }
    },
    openAddAdminModal() {
      this.isEditMode = false;
      this.admin = {
        name: "",
        email: "",
        homeScreenAccess: false,
        broadcastAccess: false,
        churchPeopleAccess: false,
        groupsAccess: false,
        servingGroupsAccess: false,
        publicEventsAccess: false,
        adminsAccess: false,
      }; // Reset the form data
      this.showAddAdminModal = true; // Open the modal
    },
    editAdmin(admin) {
      this.isEditMode = true;
      this.admin = { ...admin }; // Copy the admin object to the form data
      this.showAddAdminModal = true; // Open the modal
    },
  },
  mounted() {
    this.getAdmins(); // Fetch admins when the component is mounted
  },
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 0.75rem;
  border: 1px solid #ddd;
}

th {
  background-color: #07538f;
  color: white;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.vfm-smaller-modal {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1050;
}

/* Form Styling */
.add-admin-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="checkbox"] {
  width: auto;
  margin-right: 5px;
}

/* Action Buttons Styling */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.cancel-btn {
  background-color: #ff8500;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-btn:hover {
  background-color: #e07600;
}

.submit-btn {
  background-color: #07538f;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-btn:hover {
  background-color: #00426c;
}
</style>
