<template>
  <div class="add-cell-form">
    <h3>{{ isEdit ? "Edit Block" : "Add New Block" }}</h3>
    <form @submit.prevent="handleSubmit">
      <!-- Block Preview -->
      <div class="block-preview">
        <label>Preview </label>
        <div
          class="preview-container"
          :style="{
            aspectRatio: aspectRatioMap[formData.aspectRatio],
            backgroundColor: formData.backgroundColor,
            backgroundImage: this.formData.backgroundImageUrl
              ? `url(${this.formData.backgroundImageUrl})`
              : this.previewImage
              ? `url(${this.previewImage})`
              : 'none',
          }"
        >
          <span :style="{ color: formData.textColor }" class="preview-text">{{
            formData.cellName
          }}</span>
        </div>
      </div>

      <!-- Block Name -->
      <div class="form-group">
        <label for="cellName"
          >Title: <small style="color: grey">(Optional)</small></label
        >
        <input
          type="text"
          id="cellName"
          v-model="formData.cellName"
          placeholder="Enter Block name"
        />
      </div>

      <!-- Text Color -->
      <div class="form-group">
        <label for="textColor">
          Title Color:
          <span
            class="color-preview"
            :style="{ backgroundColor: formData.textColor }"
            @click="triggerColorPicker('textColor')"
          ></span>
        </label>
        <input
          type="color"
          id="textColor"
          v-model="formData.textColor"
          ref="textColorPicker"
          class="hidden-input"
        />
      </div>

      <!-- Background Color -->
      <div class="form-group">
        <label for="backgroundColor">
          Background Color:
          <span
            class="color-preview"
            :style="{ backgroundColor: formData.backgroundColor }"
            @click="triggerColorPicker('backgroundColor')"
          ></span>
        </label>
        <input
          type="color"
          id="backgroundColor"
          v-model="formData.backgroundColor"
          ref="backgroundColorPicker"
          class="hidden-input"
        />
      </div>

      <!-- Image Upload -->
      <div class="form-group bordered-container">
        <label for="blockImage"
          >Background Image:
          <small style="color: grey">(Optional)</small></label
        >
        <input
          type="file"
          id="blockImage"
          accept="image/*"
          @change.stop="onImageUpload"
          style="display: none"
        />
        <label
          for="blockImage"
          class="upload-button"
          :class="{ disabled: usedStorage > availableStorage }"
          :disabled="usedStorage > availableStorage"
          >Upload Image</label
        >
        <div
          class="storage-info"
          :class="{
            'text-red': usedStorage >= availableStorage * 0.9,
            'text-yellow':
              usedStorage >= availableStorage * 0.75 &&
              usedStorage < availableStorage * 0.9,
            'text-gray': usedStorage < availableStorage * 0.75,
          }"
        >
          <p v-if="usedStorage !== null">
            {{ usedStorage }}MB used of {{ availableStorage }}MB
            <button
              v-if="
                subscription.stripeProductName.toLowerCase().includes('free')
              "
              class="small-btn update-plan-btn"
              @click="openManagePlanModal"
            >
              Update Plan
            </button>
          </p>
        </div>

        <VueFinalModal
          v-model="isManagePlanModalVisible"
          content-class="vfm-center-modal"
        >
          <ManagePlan @close="closeManagePlanModal" />
        </VueFinalModal>
      </div>

      <!-- Aspect Ratio -->
      <div class="form-group">
        <label for="aspectRatio">Aspect Ratio:</label>
        <select id="aspectRatio" v-model="formData.aspectRatio">
          <option value="1:1">1:1</option>
          <option value="16:9">16:9</option>
          <option value="16:5">16:5</option>
        </select>
      </div>

      <!-- Action Picklist -->
      <div class="form-group">
        <label for="action">Action:</label>
        <select id="action" v-model="formData.action" @change="onActionChange">
          <option value="openUrl">Open URL</option>
          <option value="openPage">Open Page</option>
        </select>
      </div>

      <!-- URL -->
      <div v-if="formData.action === 'openUrl'" class="form-group">
        <label for="url">URL:</label>
        <input
          type="url"
          v-model="formData.url"
          @input="enforceWebsitePrefix"
          required
        />
      </div>

      <!-- Open in Browser -->
      <div v-if="formData.action === 'openUrl'" class="form-group">
        <label>
          <input type="checkbox" v-model="formData.openInBrowser" />
          <b>Open in Browser </b>
          <small style="color: red">MUST BE CHECKED FOR GIVING URLS</small>
        </label>
      </div>

      <!-- Active Status -->
      <div class="form-group">
        <label>
          <input type="checkbox" v-model="formData.isActive" />
          <b>Active </b>
          <small v-if="formData.isActive === false" style="color: red"
            >Only active blocks appear in the App</small
          >
        </label>
      </div>

      <!-- Submit Button -->
      <div class="form-actions">
        <button type="button" class="cancel-btn" @click="$emit('cancel')">
          Cancel
        </button>
        <button type="submit" class="common-btn">
          {{ isEdit ? "Save Changes" : "Add Block" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchStorageUsage } from "@/utils/imageService";
import { useSubscriptionStore } from "@/stores/subscription";
import { VueFinalModal } from "vue-final-modal";
import ManagePlan from "@/views/ManagePlan.vue";

export default {
  name: "AddEditCellForm",
  components: {
    ManagePlan,
    VueFinalModal,
  },
  props: {
    churchId: {
      type: Number,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    initialCellData: {
      type: Object,
      default: () => ({
        id: null,
        cellName: "",
        url: "",
        textColor: "#000000",
        backgroundColor: "#ffffff",
        parentCellId: 0,
        openInBrowser: false,
        isActive: true,
        hasChildCell: false,
        aspectRatio: "16:9",
      }),
    },
    isTopLevel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        ...this.initialCellData,
        textColor: this.initialCellData.textColor || "#000000",
        backgroundColor: this.initialCellData.backgroundColor || "#ffffff",
        aspectRatio:
          this.initialCellData.aspectRatio?.replace(" / ", ":") || "16:5",
        backgroundImageUrl: this.initialCellData.backgroundImageUrl || null,
        action:
          this.initialCellData.hasChildCell == true ? "openPage" : "openUrl",
        isActive: this.initialCellData.isActive || false,
        url: this.initialCellData.url || "https://",
      },
      previewImage: this.initialCellData.backgroundImageUrl || null,
      usedStorage: null,
      aspectRatioMap: {
        "1:1": "1 / 1",
        "16:9": "16 / 9",
        "16:5": "16 / 5",
      },
      isManagePlanModalVisible: false,
      availableStorage: 0,
      errors: {},
    };
  },
  computed: {
    subscription() {
      const subscriptionStore = useSubscriptionStore();
      return subscriptionStore.subscription;
    },
  },
  methods: {
    onActionChange() {
      if (this.formData.action === "openPage") {
        this.formData.hasChildCell = true;
        this.formData.url = "";
        this.formData.openInBrowser = false;
      } else if (this.formData.action === "openUrl") {
        this.formData.hasChildCell = false;
      }
    },
    enforceWebsitePrefix() {
      if (!this.formData.url.startsWith("https://")) {
        this.formData.url = "https://";
      }
    },
    handleSubmit() {
      const formData = {
        ...this.formData,
        backgroundImageUrl:
          this.previewImage || this.formData.backgroundImageUrl || null,
      };

      if (this.isEdit) {
        this.$emit("edit-cell", formData);
      } else {
        this.$emit("add-cell", formData);
      }
    },
    onImageUpload(event) {
      const file = event.target.files[0]; // Get the selected file

      if (file) {
        // Clear old image references
        this.previewImage = null;
        this.formData.backgroundImageUrl = null;

        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result; // Set new preview image
          this.formData.imageFile = file; // Attach the file to formData
          this.$emit("update-preview", this.previewImage); // Emit preview to parent
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          alert("Failed to read the file. Please try again.");
        };

        reader.readAsDataURL(file);
      } else {
        // File dialog was canceled; reset input without any other actions
        console.log("File dialog canceled.");
        event.target.value = ""; // Reset the file input field
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    onHasChildCellChange() {
      if (this.formData.hasChildCell) {
        this.formData.url = null;
      }
    },
    triggerColorPicker(pickerRef) {
      if (this.$refs[pickerRef]) {
        this.$refs[pickerRef].click();
      } else {
        console.error(`Color picker ref "${pickerRef}" is not defined.`);
      }
    },
    async fetchStorage() {
      const env = process.env.VUE_APP_ENV || "local";
      const category = "home";
      const churchId = this.$props.churchId;

      const usedStorage = await fetchStorageUsage(env, category, churchId);
      if (usedStorage !== null) {
        this.usedStorage = usedStorage;
      } else {
        console.error("Unable to fetch storage usage.");
      }
    },
    fetchAvailableStorage() {
      const plan = this.subscription;
      if (plan && plan.metadata) {
        try {
          const metadata = plan.metadata;
          if (metadata.storage_limit) {
            this.availableStorage = metadata.storage_limit;
          } else {
            console.error("Storage value not found in metadata.");
          }
        } catch (error) {
          console.error("Error parsing metadata:", error);
        }
      } else {
        console.error("Current plan or metadata is not defined.");
      }
    },
    openManagePlanModal() {
      this.isManagePlanModalVisible = true;
    },
    closeManagePlanModal() {
      this.isManagePlanModalVisible = false;
    },
  },
  mounted() {
    this.fetchStorage();
    this.fetchAvailableStorage();
  },
};
</script>

<style scoped>
.add-cell-form {
  max-width: 500px;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-bottom: 1rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

.upload-button {
  cursor: pointer;
  background-color: #07538f;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}

.upload-button:hover {
  background-color: #00426c;
}

.upload-button.disabled {
  cursor: not-allowed;
  background-color: #d3d3d3; /* Light gray */
  color: #808080; /* Gray text */
  pointer-events: none;
}

.bordered-container {
  border: 1px solid #ccc; /* Add a light gray border */
  padding: 1rem; /* Add some padding inside the container */
  border-radius: 8px; /* Optional: Add rounded corners */
  margin-top: 1rem; /* Add some spacing above the section */
  background-color: #f9f9f9; /* Optional: Light background for better visibility */
}

.storage-info {
  font-size: 0.7rem;
  margin-top: 0px;
  color: grey;
  align-items: center;
  gap: 8px;
}

.text-red {
  color: red;
  font-weight: bold;
}

.text-yellow {
  color: #ffa500; /* Warning yellow (orange-like) */
  font-weight: bold;
}

.text-gray {
  color: grey;
}

.small-btn {
  width: auto; /* Let the button size itself based on its content */
  display: inline-block; /* Ensure it behaves like an inline element */
  padding: 4px 8px; /* Compact padding */
  font-size: 0.8rem;
  background-color: #07538f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.small-btn:hover {
  background-color: #00426c;
}

.small-btn:active {
  background-color: #003352;
}

.small-btn:focus {
  outline: none;
  box-shadow: 0 0 2px 2px rgba(0, 83, 143, 0.5);
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.color-preview {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;
  border: 2px solid #000;
  vertical-align: middle;
}

.hidden-input {
  position: absolute !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

input,
button {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  width: auto;
}

.common-btn {
  background-color: #07538f;
  color: white;
  cursor: pointer;
}

.common-btn:hover {
  background-color: #00426c;
}

.cancel-btn {
  background-color: #ff8500;
  color: white;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #e07600;
}

.block-preview {
  margin: 1rem 0;
  text-align: center;
}

.preview-container {
  position: relative; /* Ensure positioning context for child elements */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.preview-text {
  position: absolute; /* Position relative to the container */
  bottom: 16px; /* Distance from the bottom of the container */
  left: 16px; /* Distance from the left of the container */
  font-size: 24px; /* Set the desired font size */
  font-weight: bold; /* Optional: Make the text bold */
  color: inherit; /* Use the dynamically applied text color */
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3); /* Add shadow for readability */
  z-index: 1; /* Ensure it stays above the background */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}
</style>
