<template>
  <div class="page">
    <nav class="navbar">
      <div class="navbar-brand">
        <MyChelperNavLogo />
      </div>
      <div class="nav-column">
        <button @click="goToHome" class="orange-btn">Home</button>
      </div>
    </nav>

    <div class="login-container">
      <h2>Login to</h2>
      <MyChelperHeader />
      <form @submit.prevent="login">
        <input
          v-model="email"
          type="email"
          placeholder="Enter your email"
          required
        />
        <button type="submit" class="common-btn" :disabled="isButtonDisabled">
          Send Verification Email
        </button>
      </form>
      <!-- Display success or error message -->
      <p v-if="message" class="message">{{ message }}</p>

      <!-- Conditionally display "Email Verified" button -->
      <button
        v-if="emailVerified"
        @click="goToDashboard"
        class="verified-button"
      >
        Email Verified
      </button>
    </div>

    <!-- Footer -->
    <MyChelperFooter />
  </div>
</template>

<script>
import axios from "axios";
import {
  USER_ID,
  TOKEN,
  DEVICE_UNIQUE_ID,
  USER_EMAIL,
} from "../constants/storageKeys";

export default {
  data() {
    return {
      email: "", // User input
      deviceUniqueId: "123456789abcdef", // Pre-set unique device ID
      target: "MyChelper", // Constant value for the target
      message: "", // Message to show the user
      emailVerified: false, // Tracks whether to show the "Email Verified" button
      originalEmail: "", // Tracks the email value after the last button click
      buttonClicked: false, // Tracks whether the button was clicked
    };
  },
  computed: {
    // Disable the button if it has been clicked and the email hasn't changed
    isButtonDisabled() {
      return this.buttonClicked && this.email === this.originalEmail;
    },
  },
  watch: {
    // Reset buttonClicked if the email changes
    email(newValue) {
      if (newValue !== this.originalEmail) {
        this.buttonClicked = false;
      }
    },
  },
  methods: {
    async login() {
      try {
        this.originalEmail = this.email;
        this.buttonClicked = true;

        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        await axios.post(`${apiBaseUrl}/register`, {
          email: this.email,
          deviceUniqueId: this.deviceUniqueId, // Dynamically set
          target: this.target, // Constant value
        });

        // Show success message
        this.message = "Verification email sent! Please check your inbox.";
        this.emailVerified = true;
      } catch (error) {
        // Handle errors
        this.message = "Failed to send verification email. Please try again.";
        this.emailVerified = false;
        this.buttonClicked = false; // Re-enable the button in case of failure
        // this.$forceUpdate();
      }
    },
    goToHome() {
      this.$router.push("/");
    },
    async goToDashboard() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

        // Make the API call to /verify-device
        const response = await axios.post(`${apiBaseUrl}/verify-device`, {
          email: this.email, // Send the user's email
          deviceUniqueId: this.deviceUniqueId, // Send the same device ID
        });

        // Check response data
        if (response.status === 200) {
          const { userId, token } = response.data;
          localStorage.setItem(USER_ID, userId);
          localStorage.setItem(TOKEN, token);
          localStorage.setItem(USER_EMAIL, this.email);
          localStorage.setItem(DEVICE_UNIQUE_ID, this.deviceUniqueId);
          this.$router.push("/dashboard"); // Redirect to the dashboard
        } else {
          this.message = "Device verification failed. Please try again.";
        }
      } catch (error) {
        console.error("Device verification failed:", error);
        this.message = "Device verification failed. Please try again.";
      }
    },
  },
};
</script>

<style scoped>
/* Your existing styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

input {
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 5px;
}

/* Style for the "Email Verified" button */
.verified-button {
  margin-top: 1rem;
  padding: 1rem 2rem;
  background-color: #28a745;
  /* Green color for verified */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.verified-button:hover {
  background-color: #218838;
}
</style>
