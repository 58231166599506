<template>
  <div>
    <nav class="navbar">
      <div class="navbar-brand">
        <MyChelperNavLogo />
      </div>
      <div class="nav-column">
        <button @click="goToHome" class="orange-btn">Home</button>
      </div>
    </nav>

    <div class="signup-container">
      <h1>Sign Up to Add Your Church</h1>

      <!-- Church Creation Step -->
      <div v-if="!churchCreated">
        <p>
          At <span class="brand-color">my</span
          ><span class="highlighted-brand-color">C</span
          ><span class="brand-color">helper</span>, our ultimate goal is to help
          people take one step closer to God. We are committed to partnering
          with churches that share our faith, ensuring that we are united in
          guiding people toward the truth. This way, we can have peace of mind,
          knowing that we are faithfully serving and upholding the integrity of
          our beliefs.
        </p>
        <p>
          Please read and agree to our faith statement before proceeding with
          registration.
        </p>
        <div class="faith-statement">
          <h2>Faith Statement</h2>
          <p>
            We believe in one God, eternally existing as the Father, the Son,
            and the Holy Spirit.
          </p>
          <ul>
            <li>
              <strong>We believe in God the Father,</strong> Creator of heaven
              and earth, who sustains all things by His power.
            </li>
            <br />
            <li>
              <strong>We believe in Jesus Christ,</strong> the only Son of God,
              fully divine and human, who was born of the Virgin Mary, suffered,
              died, was buried, rose on the third day, ascended into heaven, and
              will return to judge the living and the dead.
            </li>
            <br />
            <li>
              <strong>We believe in the Holy Spirit,</strong> who empowers us
              for holy living, unites us as one body, and equips us for the work
              of the Gospel.
            </li>
            <br />
          </ul>
          <p>
            The Father, Son, and Holy Spirit are equal in glory, power, and
            majesty—together, the one true God.
          </p>
        </div>
        <form @submit.prevent="createChurch">
          <h2>Church Information</h2>
          <div class="form-group">
            <label for="churchName">Church Name:</label>
            <input type="text" v-model="form.churchName" required />
          </div>

          <div class="form-group">
            <label for="website">Website:</label>
            <input
              type="url"
              v-model="form.website"
              @input="enforceWebsitePrefix"
              required
              placeholder="https://example.com"
            />
          </div>

          <div class="form-group">
            <label for="street">Street:</label>
            <input type="text" v-model="form.street" required />
          </div>

          <div class="form-group">
            <label for="city">City:</label>
            <input type="text" v-model="form.city" required />
          </div>

          <!-- State Select Field -->
          <div class="form-group">
            <select v-model="form.state" class="form-select" required>
              <option value="" disabled>Select a state</option>
              <option v-for="state in states" :key="state" :value="state">
                {{ state }}
              </option>
            </select>
          </div>

          <!-- Territory Select Field -->
          <div class="form-group" v-if="form.state">
            <select v-model="form.territoryId" class="form-select" required>
              <option value="" disabled>
                Select a territory where your church is located
              </option>
              <option
                v-for="territory in filteredTerritories"
                :key="territory.territoryId"
                :value="territory.territoryId"
              >
                {{ territory.territoryName }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="zip">Zip:</label>
            <input type="text" v-model="form.zip" required />
          </div>

          <div class="form-group">
            <input type="checkbox" v-model="form.agree" />
            <label for="agree">Our church shares the same belief</label>
          </div>
          <button type="submit" :disabled="!form.agree" class="form-button">
            Next
          </button>
        </form>
      </div>

      <!-- Success Message and Admin Info -->
      <div v-if="churchCreated">
        <p class="success-message">Church Info Saved!</p>
        <button @click="editChurch" class="orange-btn">
          Back to Church Info
        </button>
        <form @submit.prevent="submitAdminForm">
          <h2>Admin's Information</h2>
          <div class="form-group">
            <label for="firstName">First Name:</label>
            <input type="text" v-model="form.firstName" required />
          </div>

          <div class="form-group">
            <label for="lastName">Last Name:</label>
            <input type="text" v-model="form.lastName" required />
          </div>

          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" v-model="form.email" required />
            <button
              @click="verifyEmail"
              :disabled="emailVerifying"
              class="form-button"
            >
              Verify Email
            </button>
            <p v-if="verificationMessage" :class="verificationMessageClass">
              {{ verificationMessage }}
            </p>
          </div>

          <!-- "Setup your church" button -->
          <button
            type="submit"
            :disabled="!emailVerifying || isAddingChurch"
            class="form-button"
          >
            Setup your church
          </button>
        </form>
      </div>
    </div>
    <MyChelperFooter />
  </div>
</template>

<script>
import axios from "axios";
import {
  USER_ID,
  TOKEN,
  DEVICE_UNIQUE_ID,
  USER_EMAIL,
} from "../constants/storageKeys";

export default {
  data() {
    return {
      form: {
        churchName: "",
        website: "https://",
        street: "",
        city: "",
        state: "",
        zip: "",
        territoryId: "", // Added territoryId to the form data
        firstName: "",
        lastName: "",
        email: "",
        agree: false,
      },
      churchCreated: false,
      emailVerifying: false,
      emailVerified: false,
      isError: false,
      verificationMessage: "",
      isAddingChurch: false,
      states: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      territories: [], // Added territories to the data
    };
  },
  computed: {
    filteredTerritories() {
      if (!this.form.state) {
        return [];
      }
      return this.territories.filter(
        (territory) => territory.state === this.form.state
      );
    },
    verificationMessageClass() {
      return {
        "verification-message": true,
        "error-message": this.isError,
        "success-message": !this.isError,
      };
    },
  },
  watch: {
    "form.state"(newState, oldState) {
      if (newState !== oldState) {
        this.form.territoryId = ""; // Reset the territory selection
      }
    },
    "form.email"(newEmail, oldEmail) {
      if (newEmail !== oldEmail) {
        this.emailVerifying = false;
        this.verificationMessage = "";
      }
    },
  },
  mounted() {
    this.fetchTerritories();
  },
  methods: {
    enforceWebsitePrefix() {
      if (!this.form.website.startsWith("https://")) {
        this.form.website = "https://";
      }
    },
    goToHome() {
      this.$router.push("/");
    },
    async fetchTerritories() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${apiBaseUrl}/territories`);
        this.territories = response.data;
      } catch (error) {
        console.error("Error fetching territories:", error);
      }
    },
    createChurch() {
      // Logic to submit church creation data
      this.churchCreated = true;
    },
    editChurch() {
      this.churchCreated = false;
    },
    async verifyEmail() {
      this.emailVerifying = true;
      this.isError = false;
      this.verificationMessage = "Sending verification email...";

      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

        // Send the POST request with email, deviceUniqueId, and target
        await axios.post(`${apiBaseUrl}/register`, {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          deviceUniqueId: "123456789abcdef",
          target: "web",
        });
        this.isError = false;
        this.verificationMessage =
          "Verification email sent! Please check your inbox, and verify your email, and come back here to setup your church!";
      } catch (error) {
        // Error handling
        this.isError = true;
        this.verificationMessage =
          "Failed to send verification email. Please try again.";
      }
    },
    async submitAdminForm() {
      try {
        this.isAddingChurch = true;
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const verificationResponse = await axios.post(
          `${apiBaseUrl}/verify-device`,
          {
            email: this.form.email,
            deviceUniqueId: "123456789abcdef",
          }
        );

        if (verificationResponse.status === 200) {
          const { userId, token } = verificationResponse.data;
          localStorage.setItem(USER_ID, userId);
          localStorage.setItem(TOKEN, token);
          localStorage.setItem(USER_EMAIL, this.form.email);
          localStorage.setItem(DEVICE_UNIQUE_ID, "123456789abcdef");

          this.isError = false;
          this.verificationMessage =
            "Email verified! We are setting up your church";

          // Church setup network call
          const churchResponse = await axios.post(
            `${apiBaseUrl}/churches/add-church`,
            {
              churchName: this.form.churchName,
              adminFirstName: this.form.firstName,
              adminLastName: this.form.lastName,
              stripeProductId: "prod-1",
              website: this.form.website,
              street: this.form.street,
              city: this.form.city,
              state: this.form.state,
              zip: this.form.zip,
              country: "USA",
              territoryID: this.form.territoryId,
              usesAdminApp: true,
              usesCommunityTab: true,
              usesMyChurchProfile: false,
              usesPrayersPraise: true,
              usesPublicEvents: true,
              usesGroups: false,
              usesServingRequests: false,
              admin_email: this.form.email,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (churchResponse.status === 201) {
            this.isError = false;
            this.verificationMessage = "Church created successfully!";
            this.$router.push("/dashboard");
          } else {
            this.isError = true;
            this.verificationMessage =
              "Failed to set up the church. Please try again.";
            this.isAddingChurch = false;
          }
        } else {
          this.isError = true;
          this.verificationMessage =
            "Email not verified yet. Please check your inbox.";
          this.isAddingChurch = false;
        }
      } catch (error) {
        this.isAddingChurch = false;
        if (error.response) {
          if (error.response.status === 409 || error.response.status === 404) {
            this.isError = true;
            this.verificationMessage = error.response.data.message;
          } else {
            this.isError = true;
            this.verificationMessage =
              "Failed to set up the church. Please try again.";
          }
        } else {
          // General error message for other issues (e.g., network error)
          console.error("Error during church setup:", error);
          this.isError = true;
          this.verificationMessage =
            "Error during church setup. Please try again.";
        }
      } finally {
        this.isAddingChurch = false;
      }
    },
  },
};
</script>

<style scoped>
.signup-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.faith-statement {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

input[type="text"],
input[type="url"],
input[type="email"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button {
  background-color: #07538f;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.form-select {
  background-color: #07538f;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.form-select:focus {
  outline: none;
  box-shadow: 0 0 5px #ff8500;
}

.form-select option {
  color: #07538f;
  background-color: white;
}

.form-select:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.success-message {
  color: green;
  font-weight: bold;
  margin-bottom: 15px;
}

.verification-message {
  margin-top: 10px;
}

.success-message {
  color: #28a745;
  /* Green */
}

.error-message {
  color: #dc3545;
  /* Red */
}
</style>
