<template>
  <div class="page">
    <!-- Navbar -->
    <nav class="navbar">
      <div class="navbar-brand">
        <MyChelperNavLogo />
      </div>
      <div class="nav-column">
        <button @click="goToLogin" class="orange-btn">Admin Login</button>
        <button @click="goToSignup" class="orange-btn">
          Add Your Church - 100% Free
        </button>
      </div>
    </nav>

    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <h1>Empower Your Church with the Best Free Customizable Church App</h1>
        <!-- <button @click="scheduleDemo" class="cta-btn">
          Schedule Your Online Demo Now
        </button> -->
        <button @click="goToSignup" class="cta-btn">
          Add Your Church - 100% Free
        </button>
      </div>
    </section>

    <!-- Why MyChelper? -->
    <section class="section">
      <h2>
        Why <span class="brand-color">my</span
        ><span class="highlighted-brand-color">C</span
        ><span class="brand-color">helper</span>?
      </h2>
      <FeaturesSection />
    </section>

    <!-- Wondering What It Means Section -->
    <section class="info-box">
      <h2>What Does It Mean to Have Your Own Free Church Management App?</h2>
      <p>
        Adding your church to MyChelper means having a personalized church app
        where your congregation can stay connected, receive updates, and
        participate in events.
      </p>
      <p>Explore what this could look like for your church:</p>
      <div class="demo-preview">
        <p>
          Download the
          <strong
            ><span class="brand-color">my</span
            ><span class="highlighted-brand-color">C</span
            ><span class="brand-color">helper</span></strong
          >
          App:
        </p>
        <div class="app-links">
          <a
            href="https://apps.apple.com/us/app/mychelper/id6736588448"
            target="_blank"
          >
            <img
              src="../../src/assets/Download_on_the_App_Store.svg"
              alt="App Store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.jcmobdev.mychelper"
            target="_blank"
          >
            <img
              src="../../src/assets/GetItOnGooglePlay.png"
              alt="Google Play"
            />
          </a>
        </div>
        <p>
          Then, scan the QR code below or enter <strong>demo</strong> in the
          church id field to see how it works:
        </p>
        <img
          :src="require('@/assets/myChelper_QR_Code.png')"
          alt="QR Code"
          class="qr-code"
        />
      </div>
      <div class="user-example">
        <p>
          This can be your church! Your congregation will access your church on
          <strong>myChelper</strong> the same way. They will simply download the
          app and scan <strong>Your Church</strong>'s unique QR code.
        </p>
      </div>
    </section>

    <!-- Subscription Tiers -->
    <section class="subscription-tiers">
      <h2>Available Plans</h2>
      <div v-if="loading">Loading plans...</div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <div v-else class="tiers">
        <div
          v-for="plan in plans"
          :key="plan.id"
          :class="['tier', { highlighted: plan.highlighted }]"
        >
          <p v-if="plan.highlighted">
            <span class="highlighted-brand-color">Best Value</span>
          </p>
          <h3>{{ plan.name }}</h3>
          <ul style="text-align: left">
            <ul style="text-align: left">
              <li
                v-for="feature in plan.features"
                :key="feature"
                v-html="feature"
              ></li>
            </ul>
          </ul>
          <p
            class="price"
            v-if="
              plan.pricing?.monthly?.unit_amount !== null &&
              plan.pricing?.monthly?.unit_amount !== undefined
            "
          >
            {{
              formatPrice(
                plan.pricing.monthly.unit_amount,
                plan.pricing.monthly.currency
              )
            }}
            PER MONTH
          </p>
          <p class="price" v-if="plan.pricing?.annual?.unit_amount">
            {{
              formatPrice(
                plan.pricing.annual.unit_amount,
                plan.pricing.annual.currency
              )
            }}
            PER YEAR
          </p>
          <p v-if="!plan.name.toLowerCase().includes('free')">
            <b>You can upgrade after adding your church free.</b>
          </p>
          <p v-if="!plan.name.toLowerCase().includes('free')">
            By default you will have access to the <b>Ready-To-Go Free APP</b>
          </p>
          <button @click="goToSignup" class="common-btn">
            Add Your Church
          </button>
          <p>
            <span class="brand-color"><b>No Credit Card Required!</b></span>
          </p>
          <p class="price">Start Customizing Your App Today!</p>
        </div>
      </div>
      <div class="info-box">
        <p class="quote">
          <em>"It is more blessed to give than to receive" (Acts 20:35).</em>
        </p>
        <p class="description">
          By subscribing to the <strong>Ready-To-Go +</strong>, you support our
          mission of helping more churches reach more people. Your contribution
          covers operational costs and ensures that
          <span class="brand-color">my</span
          ><span class="highlighted-brand-color">C</span
          ><span class="brand-color">helper</span> remains free and accessible.
        </p>
        <div class="help-box">
          <p>
            <strong>Need help?</strong> If your church is growing but cannot
            afford the monthly subscription, please
            <a
              href="mailto:care@mychelper.com?subject=Subscription%20Assistance"
              >contact us</a
            >. We will gladly waive the subscription fee.
          </p>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <MyChelperFooter />
  </div>
</template>

<script>
import axios from "axios";
import FeaturesSection from "@/components/FeaturesSection.vue";

export default {
  metaInfo: {
    title: "MyChelper - Free Customizable Church App for Community Engagement",
    meta: [
      // Primary description for Google search
      {
        name: "description",
        content:
          "Looking for a free, customizable app to manage your church? Discover MyChelper! Engage your community with notifications, event management, and more—100% free.",
      },
      // Keywords to target relevant searches
      {
        name: "keywords",
        content:
          "free church app, customizable church app, church management app, community engagement tools, church software, church event management, apps for churches, church communication tools, digital tools for churches, church apps free download, free app for church growth, how to create a church app, manage your church online",
      },
      // Open Graph meta tags for social media sharing
      {
        property: "og:title",
        content:
          "MyChelper - Free Customizable Church App for Community Engagement",
      },
      {
        property: "og:description",
        content:
          "Engage your community and manage your church with MyChelper. Notifications, events, and more—100% customizable and free!",
      },
      {
        property: "og:url",
        content: "https://www.mychelper.com",
      },
      {
        property: "og:image",
        content: "https://www.mychelper.com/assets/social-share-image.png", // Replace with an actual image URL.
      },
      {
        property: "og:type",
        content: "website",
      },
      // Twitter meta tags for improved sharing
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:title",
        content:
          "MyChelper - Free Customizable Church App for Community Engagement",
      },
      {
        name: "twitter:description",
        content:
          "Need a free app for your church? MyChelper offers a customizable app for notifications, event management, and more.",
      },
      {
        name: "twitter:image",
        content: "https://www.mychelper.com/assets/social-share-image.png", // Replace with an actual image URL.
      },
    ],
  },
  data() {
    return {
      plans: [],
      loading: true,
      error: null,
    };
  },
  components: {
    FeaturesSection,
  },
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    goToSignup() {
      this.$router.push("/signup");
    },
    scheduleDemo() {
      window.open("https://calendly.com/jmorris-7", "_blank");
    },
    formatPrice(amount, currency) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency.toUpperCase(),
        minimumFractionDigits: 0,
      }).format(amount / 100);
    },
    async fetchPlans() {
      const cacheKey = "plansCache";
      const cacheExpiryKey = "plansCacheExpiry";

      // Check if cache exists and is still valid (e.g., valid for 1 hour)
      const cachedPlans = JSON.parse(localStorage.getItem(cacheKey));
      const cacheExpiry = localStorage.getItem(cacheExpiryKey);

      if (cachedPlans && cacheExpiry && new Date(cacheExpiry) > new Date()) {
        this.plans = cachedPlans; // Use cached data
        this.loading = false;
        return;
      }

      // Fetch from the API if no valid cache exists
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      try {
        const response = await axios.get(`${apiBaseUrl}/products`);
        if (response.data.success) {
          const plans = Object.values(response.data.data).sort((a, b) => {
            const aOrder = parseInt(a.metadata.home_page_order, 10) || 0;
            const bOrder = parseInt(b.metadata.home_page_order, 10) || 0;
            return aOrder - bOrder;
          });

          // Save plans and expiry time (e.g., 1 hour) to localStorage
          localStorage.setItem(cacheKey, JSON.stringify(plans));
          localStorage.setItem(
            cacheExpiryKey,
            new Date(new Date().getTime() + 60 * 60 * 1000) // 1-hour expiry
          );

          this.plans = plans;
        } else {
          this.error = "Failed to load plans.";
        }
      } catch (error) {
        this.error = "An error occurred while fetching plans.";
        console.error("Fetch plans error:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchPlans();
  },
};
</script>

<style>
/* Styling for Wondering Section */
.wondering-section {
  background-color: #f7f7f7;
  padding: 3rem 1rem;
  text-align: center;
  margin-top: 2rem;
}

.wondering-section h2 {
  font-size: 1.75rem;
  color: #07538f;
  margin-bottom: 1rem;
}

.wondering-section p {
  font-size: 1.15rem;
  margin-bottom: 1rem;
  color: #333;
}

.demo-preview {
  margin-top: 2rem;
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
}

.app-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.app-links img {
  height: 50px;
  width: auto;
}

.qr-code {
  margin-top: 1rem;
  width: 150px;
  height: 150px;
}

.user-example {
  margin-top: 1.5rem;
  font-size: 1.15rem;
  color: #333;
  line-height: 1.5;
}

.why-mychelper-container {
  display: flex;
  align-items: center; /* Vertically align items */
  justify-content: space-between; /* Maintain even spacing */
  column-gap: 0px; /* Fixed spacing between text and image */
  max-width: 1200px; /* Limit container width */
  margin: 0 auto; /* Center the container */
  padding: 0 2rem; /* Equal padding on left and right */
  text-align: left; /* Align text to the left */
}

.why-mychelper-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center text vertically */
  margin-right: 20px; /* Fixed spacing between text and image */
}

.why-mychelper-image {
  flex: 1;
  text-align: center; /* Center the image horizontally */
  display: flex;
  align-items: center; /* Center the image vertically */
  justify-content: center;
}

.why-mychelper-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .why-mychelper-container {
    flex-direction: column; /* Stack text and image vertically */
    row-gap: 20px; /* Fixed spacing between rows */
    padding: 0 1rem; /* Reduce padding on smaller screens */
  }

  .why-mychelper-text,
  .why-mychelper-image {
    flex: none; /* Remove equal space distribution */
    width: 100%; /* Full width for both containers */
    margin-right: 0; /* Remove margin for stacked layout */
  }
}

/* Styling the info box for better visual appeal */
.info-box {
  width: 80%;
  max-width: 777px;
  background-color: #f7f7f7;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 2rem auto; /* Horizontally centers the box */
  display: flex; /* To allow alignment */
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
}

/* Styling the quote */
.quote {
  font-style: italic;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #07538f;
  border-left: 4px solid #ff8500;
  padding-left: 1rem;
}

/* Adding emphasis to the description */
.description {
  font-size: 1.15rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #333;
}

/* Help box styling */
.help-box {
  background-color: #ff8500;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 1rem;
}

.help-box a {
  color: #07538f;
  text-decoration: underline;
  font-weight: bold;
}
</style>
