import { defineStore } from "pinia";

export const useAdminStore = defineStore("admin", {
  state: () => ({
    admin: null, // Holds the admin data
  }),
  actions: {
    /**
     * Update the admin data
     */
    updateAdmin(adminData) {
      this.admin = adminData;
    },
  },
});
