<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>Effective Date: 01/07/2025</p>
    <p>
      At MyChelper, your privacy is important to us. This Privacy Policy
      explains how we collect, use, and protect your personal information when
      you use our services. By using MyChelper, you agree to the practices
      outlined in this policy.
    </p>

    <h2>1. Information We Collect</h2>
    <ul>
      <li>
        <strong>Personal Data:</strong> We collect your name, email address, and
        physical address during account creation. Payment information is
        processed securely by Stripe and is not stored on our servers.
      </li>
      <li>
        <strong>Sensitive Data:</strong> We do not collect sensitive data.
        However, churches must confirm they share the same faith as outlined in
        our terms by checking a box during the church creation process.
      </li>
      <li>
        <strong>Children’s Data:</strong> MyChelper does not knowingly collect
        data from children under 18.
      </li>
    </ul>

    <h2>2. How We Collect Your Data</h2>
    <p>
      We collect data directly from you through forms you complete within the
      app, such as during account creation or church setup.
    </p>

    <h2>3. Why We Collect Your Data</h2>
    <ul>
      <li>
        <strong>Account Creation and Management:</strong> To create and manage
        user and church accounts.
      </li>
      <li>
        <strong>App Functionality:</strong> To ensure the app operates as
        intended.
      </li>
      <li>
        <strong>Billing:</strong> To process payments securely through Stripe.
      </li>
    </ul>
    <p>
      We do not currently use your data for analytics or marketing purposes.
      However, these features may be added in the future.
    </p>

    <h2>4. Consent for Data Collection</h2>
    <p>
      By using MyChelper and providing your information, you consent to the
      collection and use of your data as described in this Privacy Policy.
    </p>

    <h2>5. Third-Party Services</h2>
    <ul>
      <li>
        <strong>Data Sharing:</strong> We share data with Stripe for payment
        processing. Stripe has its own Privacy Policy that you should review.
      </li>
      <li>
        <strong>Future Analytics Tools:</strong> We may integrate analytics
        tools in the future to improve app performance, but no such tools are
        currently in use.
      </li>
      <li>
        <strong>International Data Transfers:</strong> If you reside outside the
        United States and add a church to MyChelper, your data will be stored on
        servers located in the United States. By using MyChelper, you consent to
        this transfer and storage of your data in the United States, which may
        have different data protection laws than your country of residence.
      </li>
    </ul>

    <h2>6. Data Retention</h2>
    <ul>
      <li>We retain user data indefinitely.</li>
      <li>
        If an account is deleted, we may manually clean data on a case-by-case
        basis, but there is no automated process currently implemented.
      </li>
      <li>
        We do not currently offer a cancellation or deletion process through the
        app.
      </li>
    </ul>

    <h2>7. User Rights</h2>
    <p>Users have the following rights regarding their data:</p>
    <ul>
      <li>
        <strong>Access:</strong> You can request to view the personal data we
        have on file.
      </li>
      <li>
        <strong>Correction:</strong> You can request corrections to inaccurate
        data.
      </li>
      <li>
        <strong>Deletion:</strong> You may request your data be deleted, subject
        to applicable retention policies.
      </li>
      <li>
        <strong>Data Portability:</strong> You may request your data in a
        portable format.
      </li>
    </ul>
    <p>
      To exercise these rights: Users can contact our support team at
      <a href="mailto:support@mychelper.com">support@mychelper.com</a> or use
      the app’s user interface where available.
    </p>

    <h2>8. Opt-Out Policy</h2>
    <ul>
      <li>
        Opting out of data collection is not possible if you wish to use core
        app features like adding churches.
      </li>
      <li>We do not send marketing communications.</li>
    </ul>

    <h2>9. Security Measures</h2>
    <ul>
      <li>
        We implement basic security measures, including manual security audits.
      </li>
      <li>
        While we do not store sensitive data, we are committed to safeguarding
        the personal information we collect.
      </li>
    </ul>

    <h2>10. Contact Information</h2>
    <p>
      For questions or concerns about this Privacy Policy or to exercise your
      rights, please contact us at:
    </p>
    <p>
      Email: <a href="mailto:support@mychelper.com">support@mychelper.com</a>
    </p>

    <h2>11. Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. Any changes will be
      posted on this page with an updated “Effective Date.” Continued use of the
      app constitutes your acceptance of any changes.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyView",
};
</script>

<style scoped>
.privacy-policy {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.privacy-policy h1,
.privacy-policy h2 {
  color: #333;
}

.privacy-policy p,
.privacy-policy ul {
  color: #555;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>
