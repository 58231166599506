<template>
  <div>
    <h2>Payment Information</h2>

    <!-- Form Section -->
    <form @submit.prevent="handleSubmit">
      <!-- Saved Cards Section -->
      <div
        v-if="savedCards.length > 0 && !showAddPaymentMethod"
        class="form-section"
      >
        <h3>Use Existing Payment Method</h3>
        <table class="saved-cards-table">
          <thead>
            <tr>
              <th>Selected</th>
              <th>Card Details</th>
              <th>Expiration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="card in savedCards" :key="card.id" class="saved-card">
              <td>
                <input
                  type="radio"
                  :id="`card-${card.id}`"
                  :value="card.id"
                  v-model="selectedCard"
                />
              </td>
              <td>
                <label :for="`card-${card.id}`">
                  **** **** **** {{ card.last4 }} - {{ card.brand }}
                </label>
              </td>
              <td>{{ card.exp_month }}/{{ card.exp_year }}</td>
              <td>
                <button @click="deleteCard(card.id)" class="delete-btn">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="showAddPaymentMethodForm" class="add-payment-btn">
          Add a New Payment Method
        </button>
      </div>

      <!-- Add New Payment Method Section -->
      <div
        v-if="showAddPaymentMethod || savedCards.length === 0"
        class="form-section"
      >
        <h3>Create New Payment Method</h3>
        <div id="card-element" ref="cardElement" class="card-element"></div>
        <div id="card-errors" role="alert" class="error">{{ cardError }}</div>
      </div>

      <!-- Billing Address Section -->
      <div
        v-if="showAddPaymentMethod || savedCards.length === 0"
        class="form-section"
      >
        <h3>Billing Address</h3>
        <div class="form-row two-column">
          <div>
            <label for="first-name">First Name</label>
            <input
              id="first-name"
              type="text"
              v-model="billingDetails.firstName"
              required
              placeholder="Enter first name"
            />
          </div>
          <div>
            <label for="last-name">Last Name</label>
            <input
              id="last-name"
              type="text"
              v-model="billingDetails.lastName"
              required
              placeholder="Enter last name"
            />
          </div>
          x
        </div>
        <div class="form-row">
          <label for="address-line1">Address Line 1</label>
          <input
            id="address-line1"
            type="text"
            v-model="billingDetails.address1"
            required
            placeholder="Enter address"
          />
        </div>
        <div class="form-row">
          <label for="address-line2">Address Line 2 (optional)</label>
          <input
            id="address-line2"
            type="text"
            v-model="billingDetails.address2"
            placeholder="Apartment, suite, etc."
          />
        </div>
        <div class="form-row two-column">
          <div>
            <label for="city">City</label>
            <input
              id="city"
              type="text"
              v-model="billingDetails.city"
              required
              placeholder="Enter city"
            />
          </div>
          <div>
            <label for="state">State/Province</label>
            <input
              id="state"
              type="text"
              v-model="billingDetails.state"
              placeholder="Enter state/province"
            />
          </div>
        </div>
        <div class="form-row two-column">
          <div>
            <label for="country">Country</label>
            <select id="country" v-model="billingDetails.country" required>
              <option value="US">United States</option>
            </select>
          </div>
          <div>
            <label for="postal-code">ZIP/Postal Code</label>
            <input
              id="postal-code"
              type="text"
              v-model="billingDetails.zipCode"
              required
              placeholder="Enter ZIP/Postal Code"
            />
          </div>
        </div>
      </div>

      <!-- Processing State -->
      <div v-if="isProcessing" class="processing-message">
        Processing your payment, Do not close this page! please wait...
      </div>

      <!-- Success or Error Messages -->
      <div
        v-if="successMessage"
        class="success-message"
        v-html="successMessage"
      ></div>
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <!-- Subscription Details Section -->
      <div class="subscription-details">
        <h2>Subscription Details</h2>
        <div class="plan-info">
          <div>
            <p class="plan-label">Plan:</p>
            <p class="plan-value">{{ selectedPlan.name }}</p>
          </div>
          <div>
            <p class="plan-label">Price:</p>
            <p class="plan-value">{{ formatPrice(selectedPlan.price) }}</p>
          </div>
          <div>
            <p class="plan-label">Billing Cycle:</p>
            <p class="plan-value">{{ selectedPlan.billingCycle }}</p>
          </div>
        </div>
        <hr />
        <div class="total-info">
          <div class="total-label">Total Due Now:</div>
          <div class="total-value">{{ formatPrice(selectedPlan.price) }}</div>
        </div>
        <div class="terms">
          By clicking "Subscribe," you agree to our
          <a href="/terms" target="_blank">Terms</a> and
          <a href="/privacy" target="_blank">Privacy Policy</a>. Your
          subscription automatically renews. You can cancel anytime by
          subscribing to our free plan.
        </div>
        <div class="buttons">
          <button @click="$emit('backToPlanSelection')" class="back-btn">
            Back
          </button>
          <button
            @click="confirmSubscription"
            :disabled="isProcessing || isSuccessful"
            class="purchase-btn"
          >
            <i class="lock-icon"></i> Subscribe
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { EventBus } from "@/utils/eventBus.js";
import axios from "axios";

export default {
  props: {
    selectedPlan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stripe: null,
      elements: null,
      cardElement: null,
      isProcessing: false,
      isSuccessful: false,
      cardError: "",
      billingDetails: {
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "US",
        zipCode: "",
      },
      savedCards: [],
      selectedCard: null,
      showAddPaymentMethod: false,
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    formatPrice(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount / 100); // Assuming price is in cents
    },
    async initializeStripe() {
      const publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
      this.stripe = await loadStripe(publishableKey);
      this.elements = this.stripe.elements();

      this.cardElement = this.elements.create("card", {
        style: { base: { fontSize: "16px", color: "#32325d" } },
      });

      this.cardElement.mount(this.$refs.cardElement);
      this.cardElement.on("change", (event) => {
        this.cardError = event.error ? event.error.message : "";
      });
    },
    showAddPaymentMethodForm() {
      this.showAddPaymentMethod = true;
      this.selectedCard = null;
      this.$nextTick(() => {
        this.initializeStripe(); // Reload Stripe to ensure proper loading
      });
    },
    async deleteCard(cardId) {
      const confirmDelete = confirm(
        "Are you sure you want to delete this card?"
      );
      if (!confirmDelete) {
        return;
      }

      const token = localStorage.getItem("token");
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      try {
        const response = await axios.delete(
          `${apiBaseUrl}/delete-payment-method`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: { paymentMethodId: cardId },
          }
        );

        if (response.data.success) {
          this.savedCards = this.savedCards.filter(
            (card) => card.id !== cardId
          );

          if (this.savedCards.length === 0) {
            this.initializeStripe();
          }
        } else {
          console.error("Failed to delete card:", response.data.message);
        }
      } catch (error) {
        console.error(
          "Error deleting card:",
          error.response ? error.response.data : error.message
        );
      }
    },
    async fetchSavedCards() {
      const token = localStorage.getItem("token");
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      try {
        const response = await axios.post(
          `${apiBaseUrl}/payment-methods`,
          {
            customerId: this.selectedPlan.customerId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          const paymentMethods = response.data.paymentMethods;

          // Example logic: Use the first card as the default (or apply your own logic)
          const defaultCardId =
            paymentMethods.length > 0 ? paymentMethods[0].id : null;

          this.savedCards = paymentMethods.map((method) => ({
            id: method.id,
            last4: method.card.last4,
            exp_month: method.card.exp_month,
            exp_year: method.card.exp_year,
            brand: method.card.display_brand,
            isDefault: method.id === defaultCardId, // Mark the default card
          }));

          // Automatically select the default card
          this.selectedCard = defaultCardId;
        } else {
          console.error(
            "Failed to fetch payment methods:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    },
    async confirmSubscription() {
      this.isProcessing = true;

      try {
        let paymentMethodId;

        // If a saved card is selected, use its ID
        if (this.selectedCard) {
          paymentMethodId = this.selectedCard;
        } else {
          // Otherwise, create a new payment method using Stripe Element
          if (!this.cardElement) {
            throw new Error("Card Element is not mounted.");
          }

          const { paymentMethod, error } =
            await this.stripe.createPaymentMethod({
              type: "card",
              card: this.cardElement,
              billing_details: {
                name: `${this.billingDetails.firstName} ${this.billingDetails.lastName}`,
                address: {
                  line1: this.billingDetails.address1,
                  line2: this.billingDetails.address2,
                  city: this.billingDetails.city,
                  state: this.billingDetails.state,
                  postal_code: this.billingDetails.zipCode,
                  country: this.billingDetails.country,
                },
              },
            });

          if (error) {
            this.cardError = error.message;
            this.isProcessing = false;
            return;
          }

          paymentMethodId = paymentMethod.id;
        }

        // Payload for subscription update
        const payload = {
          subscriptionId: this.selectedPlan.subscriptionId,
          priceId: this.selectedPlan.priceId,
          paymentMethodId: paymentMethodId,
        };

        // Send the payload to your server
        const token = localStorage.getItem("token");
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.post(
          `${apiBaseUrl}/update-subscription`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          this.isSuccessful = true;
          // Wait for 1 second to make sure the subscription is updated in the backend
          await new Promise((resolve) => setTimeout(resolve, 1000));
          this.successMessage =
            "<b>Your subscription has been successfully updated!</b><br>We are praying for your church! May the Lord bless you!";
          EventBus.emit("refreshSubscription");
        } else {
          console.error("Error updating subscription:", response.data.message);
          this.cardError = response.data.message;
          this.errorMessage = response.data.message;
        }
      } catch (err) {
        console.error("Error confirming subscription:", err);
        this.cardError = err.response?.data?.message || "An error occurred.";
        this.errorMessage = err.response?.data?.message || "An error occurred.";
      } finally {
        this.isProcessing = false;
      }
    },
  },
  mounted() {
    this.fetchSavedCards();
    this.initializeStripe();
  },
};
</script>

<style>
.form-section {
  margin-bottom: 24px;
}
.form-row {
  margin-bottom: 15px;
  padding-right: 24px;
}
.two-column {
  display: flex;
  gap: 20px;
}
.two-column > div {
  flex: 1;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}
.saved-cards-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.saved-cards-table th,
.saved-cards-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}
.saved-cards-table th {
  background-color: #f7f7f7;
  font-weight: bold;
}
.saved-cards-table td input {
  margin: 0;
}
.error {
  color: red;
  margin-top: 10px;
}
.submit-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.submit-btn:disabled {
  background-color: #ccc;
}

.add-payment-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.add-payment-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}
.add-payment-btn:active {
  background-color: #003f8c;
  transform: translateY(0);
}

/* Delete Button */
.delete-btn {
  background-color: #dc3545;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.delete-btn:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}
.delete-btn:active {
  background-color: #a71d2a;
  transform: translateY(0);
}

button {
  margin-top: 10px;
}

/* General table styling for better button alignment */
.saved-cards-table td {
  vertical-align: middle;
}
.saved-cards-table {
  margin-bottom: 20px;
}
.subscription-details {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}
.plan-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.plan-name {
  font-size: 16px;
  font-weight: bold;
}
.plan-price {
  font-size: 16px;
  font-weight: bold;
  color: #555;
}
.total-info {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
.total-amount {
  color: #333;
}
.terms {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}
.terms a {
  color: #007bff;
  text-decoration: none;
}
.terms a:hover {
  text-decoration: underline;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.back-btn {
  background: none;
  border: none;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
}
.back-btn:hover {
  text-decoration: underline;
}
.purchase-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.purchase-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.purchase-btn i.lock-icon::before {
  content: "🔒";
  margin-right: 5px;
}
.processing-message {
  font-size: 16px;
  color: #333;
  background-color: #fff3cd;
  border: 1px solid #fff3cd;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
}
.success-message {
  font-size: 16px;
  color: #28a745;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
}
.error-message {
  font-size: 16px;
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
