<template>
  <div class="modal-overlay" @click="$emit('close')">
    <div v-if="event" class="event-card">
      <h3>{{ event.eventName }}</h3>
      <p v-if="event.isRecurring">
        <strong>Happening:</strong>
        {{ formatRecurringPattern(event.event_recurring_pattern) }} at
        {{ formatTime(event.startTime) }} - Recurring until
        {{
          formatDate(event?.event_recurring_pattern?.recurringEndDate) || "N/A"
        }}
      </p>
      <p v-else-if="event.startDate">
        <strong>Date:</strong> {{ formatDate(event.startDate) }} -
        {{ event.endDate ? formatDate(event.endDate) : "N/A" }}
      </p>
      <p v-else>
        <strong>Starts at:</strong>
        {{ event.isAllDayEvent ? "All Day" : formatTime(event.startTime) }}
      </p>
      <p>
        <strong>Ends at:</strong>
        {{ event.isAllDayEvent ? "All Day" : formatTime(event.endTime) }}
      </p>
      <p><strong>Time Zone:</strong> {{ event.timeZone }}</p>
      <p><strong>Location:</strong> {{ event.eventLocation }}</p>
      <p><strong>Description:</strong> {{ event.eventDescription }}</p>
      <p>
        <strong>Livestream:</strong>
        {{ event.isLivestreamEvent ? event.livestreamURL : "N/A" }}
      </p>
      <p><strong>Public Event:</strong> {{ event.isPublic ? "Yes" : "No" }}</p>
      <div class="actions">
        <button class="close-btn" @click="$emit('close')">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatRecurringPattern,
  formatDate,
  formatTime,
} from "@/utils/dateUtils";

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate,
    formatTime,
    formatRecurringPattern,
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 100%; /* Take full width of parent */
  max-width: 600px; /* Prevent modal from being too wide */
  max-height: 90vh; /* Limit height */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  box-sizing: border-box; /* Include padding in width */
}

.event-card {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.actions {
  margin-top: 10px;
}
close-btn {
  margin-right: 5px;
}
</style>
