<template>
  <div class="page">
    <!-- Header -->
    <div class="header">
      <button
        v-if="notifications.length > 0"
        @click="openNotificationForm"
        class="common-btn"
        :disabled="notificationsCount >= notificationsLimit"
      >
        Send Mass Notification
      </button>
      <p>
        You can send mass notifications to all users who have enabled
        notifications.
      </p>
    </div>

    <div v-if="notificationsLimit !== 777">
      <p>
        You sent
        <b>{{ notificationsCount }} out of {{ notificationsLimit }}</b>
        notifications, your limit will reset on
        <b>{{ subscription.renewalDate.split("T")[0] }}</b
        >.
      </p>
      <button @click="openManagePlanModal" class="small-btn">
        Update Plan
      </button>
    </div>

    <VueFinalModal
      v-model="isManagePlanModalVisible"
      content-class="vfm-center-modal"
    >
      <ManagePlan @close="closeManagePlanModal" />
    </VueFinalModal>

    <!-- Notification Form Modal -->
    <div
      v-if="showNotificationForm"
      class="form-overlay"
      @click="closeNotificationForm"
    >
      <div class="form-modal" @click.stop>
        <h3>Send Mass Notification</h3>
        <form @submit.prevent="handleSubmit">
          <!-- Title -->
          <div class="form-group">
            <label for="title">Title:</label>
            <input
              type="text"
              id="title"
              v-model="formData.title"
              placeholder="Enter notification title"
              required
            />
          </div>

          <!-- Dynamic Field Based on Notification Type -->
          <div class="form-group">
            <label for="body">Notification Body:</label>
            <textarea
              id="body"
              v-model="formData.body"
              placeholder="Enter notification body"
              required
            ></textarea>
          </div>

          <!-- Notification Type -->
          <div class="form-group">
            <label for="type">Notification Type:</label>
            <select
              id="type"
              v-model="formData.type"
              @change="handleTypeChange"
              required
            >
              <option value="text">Text</option>
              <option value="url">URL</option>
              <option value="image">Image</option>
            </select>
          </div>

          <!-- Dynamic Field Based on Notification Type -->
          <div class="form-group" v-if="formData.type === 'text'">
            <label for="body">Full Message:</label>
            <textarea
              id="body"
              v-model="formData.text"
              placeholder="Enter the full message"
              required
            ></textarea>
          </div>

          <div class="form-group" v-if="formData.type === 'url'">
            <label for="url">YouTube URL:</label>
            <input
              type="url"
              id="url"
              v-model="formData.url"
              placeholder="Enter URL"
              required
            />
          </div>

          <div class="form-group" v-if="formData.type === 'image'">
            <label for="image">Upload Image:</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              @change="handleImageUpload"
              required
            />
          </div>

          <!-- Submit and Cancel Buttons -->
          <div class="form-actions">
            <button
              type="button"
              class="cancel-btn"
              @click="closeNotificationForm"
            >
              Cancel
            </button>
            <button type="submit" class="common-btn">Send Notification</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Conditional Rendering for Notifications -->
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else>
      <div v-if="notifications.length === 0" class="empty-notifications">
        <p>
          You have not sent any Mass Notifications yet, Start by creating your
          first Mass Notification Message.
        </p>
        <button @click="openNotificationForm" class="common-btn">
          Send Mass Notification
        </button>
      </div>
      <div v-else class="notification-table">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Short Message</th>
              <th>Full Message</th>
              <th>Message Type</th>
              <th>Status</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notification in notifications" :key="notification.id">
              <td>{{ notification.messageTitle }}</td>
              <td>{{ notification.shortMessage }}</td>
              <td>
                <div v-if="notification.messageType === 'image'">
                  <img
                    :src="notification.fullMessage"
                    alt="Notification Image"
                    style="
                      max-width: 100px;
                      max-height: 100px;
                      object-fit: cover;
                    "
                  />
                </div>
                <div v-else-if="notification.messageType === 'url'">
                  <a
                    :href="notification.fullMessage"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ notification.fullMessage }}
                  </a>
                </div>
                <div v-else>
                  {{ notification.fullMessage }}
                </div>
              </td>
              <td>{{ notification.messageType }}</td>
              <td>
                <span
                  :class="{
                    success: notification.delivered,
                    failed: !notification.delivered,
                  }"
                >
                  {{ notification.delivered ? "Delivered" : "Failed" }}
                </span>
              </td>
              <td>
                <button
                  class="delete-btn"
                  @click="deleteNotification(notification.id)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { uploadImage, deleteImage } from "@/utils/imageService";
import { useSubscriptionStore } from "@/stores/subscription";
import { VueFinalModal } from "vue-final-modal";
import ManagePlan from "@/views/ManagePlan.vue";

export default {
  name: "MassNotifications",
  components: {
    VueFinalModal,
    ManagePlan,
  },
  props: {
    churchId: {
      type: Number,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    urlName: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    subscription() {
      const subscriptionStore = useSubscriptionStore();
      return subscriptionStore.subscription;
    },
    notificationsLimit() {
      return Number(this.subscription?.metadata.notifications_limit || 3);
    },
  },
  data() {
    return {
      notifications: [],
      loading: true,
      error: null,
      showNotificationForm: false,
      isManagePlanModalVisible: false,
      notificationsCount: 0,
      formData: {
        title: "",
        body: "",
        type: "text", // Default type
        text: "",
        url: "https://",
      },
      imageFile: null,
    };
  },
  methods: {
    async fetchNotifications() {
      this.fetchNotificationsCount();
      try {
        this.loading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/churches/inbox`,
          { churchId: this.churchId },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.notifications = Array.isArray(response.data) ? response.data : [];
      } catch (err) {
        this.error = "Failed to fetch notifications.";
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchNotificationsCount() {
      try {
        this.loading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/churches/church-inbox-count`,
          {
            churchId: this.churchId,
            startDate: new Date(
              new Date(this.subscription.renewalDate).setMonth(
                new Date(this.subscription.renewalDate).getMonth() - 1
              )
            )
              .toISOString()
              .split("T")[0],
            endDate: new Date(this.subscription.renewalDate)
              .toISOString()
              .split("T")[0],
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.success) {
          this.notificationsCount = response.data.count;
        } else {
          this.error = response.data.message;
        }
      } catch (err) {
        this.error = "Failed to fetch notifications.";
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    openNotificationForm() {
      this.showNotificationForm = true;
    },
    closeNotificationForm() {
      this.showNotificationForm = false;
      this.resetFormData();
    },
    openManagePlanModal() {
      this.isManagePlanModalVisible = true;
    },
    closeManagePlanModal() {
      this.isManagePlanModalVisible = false;
    },
    resetFormData() {
      this.formData = {
        title: "",
        body: "",
        type: "text",
      };
      this.imageFile = null;
    },
    handleTypeChange() {
      // Clear the body/input field when type changes
      this.formData.text = "";
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageFile = file;
      }
    },
    async handleSubmit() {
      try {
        const formData = new FormData();
        formData.append("urlName", this.urlName);
        formData.append("churchId", this.churchId);
        formData.append("messageTitle", this.formData.title);
        formData.append("shortMessage", this.formData.body);
        formData.append("messageType", this.formData.type);
        formData.append("viedoUrl", "");
        formData.append("createdBy", this.userId);

        // Append additional fields dynamically based on the type
        if (this.formData.type === "image") {
          if (this.imageFile) {
            const uploadResponse = await uploadImage(
              this.imageFile,
              "inbox",
              this.churchId
            );

            if (!uploadResponse) {
              alert("Image upload failed!");
              return;
            }

            // Set the `fullMessage` to the uploaded image URL
            formData.append("fullMessage", uploadResponse.url);
            formData.append("imageDeleteHash", uploadResponse.deleteHash);
          } else {
            alert("Please upload an image.");
            return;
          }
        } else if (this.formData.type === "url") {
          formData.append("fullMessage", this.formData.url);
        } else {
          formData.append("fullMessage", this.formData.text);
        }

        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.post(
          `${apiBaseUrl}/churches/admin-inbox-send`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        alert("Notification sent successfully!");
        console.log("Response:", response.data);

        // Close the modal and refresh the notifications list
        this.closeNotificationForm();
        this.fetchNotifications();
      } catch (err) {
        alert("Failed to send notification.");
        console.error("Error:", err);

        // Handle specific errors (optional)
        if (err.response && err.response.data.errors) {
          const errorMessages = Object.values(err.response.data.errors)
            .flat()
            .join("\n");
          alert(`Validation Errors:\n${errorMessages}`);
        }
      }
    },
    async deleteNotification(notificationId) {
      if (!confirm("Are you sure you want to delete this notification?")) {
        return;
      }

      try {
        const notification = this.notifications.find(
          (n) => n.id === notificationId
        );

        if (!notification) {
          alert("Notification not found.");
          return;
        }

        if (
          notification.messageType === "image" &&
          notification.imageDeleteHash
        ) {
          try {
            await deleteImage(notification.imageDeleteHash);
            console.log("Image deleted.");
          } catch (error) {
            console.error("Failed to delete image:", error);
            alert(
              "Failed to delete the image. Proceeding with notification deletion."
            );
          }
        }

        // Delete the notification from the backend
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        await axios.post(
          `${apiBaseUrl}/churches/inbox/soft-delete`,
          { id: notificationId },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        alert("Notification deleted successfully!");
        // Refresh the notifications list
        this.fetchNotifications();
      } catch (err) {
        console.error("Error deleting notification:", err);
        alert("Failed to delete notification.");
      }
    },
  },
  mounted() {
    this.fetchNotifications();
  },
};
</script>

<style>
.header .common-btn {
  margin-top: 1rem; /* Adjust the spacing as needed */
}

.mass-notifications {
  padding: 24;
  max-width: 800px;
  margin: 0 auto;
}

/* Modal Styling */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-modal {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  overflow-y: auto;
}

/* Form Styles */
.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input,
textarea,
select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  resize: none;
  height: 100px;
}

/* Buttons */
.common-btn,
.cancel-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.common-btn {
  background-color: #07538f;
  color: white;
}

.common-btn:hover {
  background-color: #00426c;
}

.cancel-btn {
  background-color: #ff8500;
  color: white;
}

.cancel-btn:hover {
  background-color: #e07600;
}

.notification-table {
  margin-top: 1rem;
  width: 100%;
  overflow-x: auto;
}

.success {
  color: green;
  font-weight: bold;
}

.failed {
  color: red;
  font-weight: bold;
}
</style>
