import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import SignUp from "@/views/SignUp.vue";
import TermsView from "@/views/Legal/TermsView.vue";
import PrivacyPolicyView from "@/views/Legal/PrivacyPolicyView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = !!localStorage.getItem("token");
      if (isAuthenticated) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard",
    name: "DashboardView",
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/terms",
    name: "TermsView",
    component: TermsView,
  },
  {
    path: "/privacy",
    name: "PrivacyPolicyView",
    component: PrivacyPolicyView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Add a global navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token"); // Check if the user is authenticated

  if (to.meta.requiresAuth && !isAuthenticated) {
    // If route requires auth and user is not authenticated, redirect to login
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else {
    next(); // Otherwise, allow navigation
  }
});

export default router;
