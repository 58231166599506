<template>
  <nav class="navbar">
    <MyChelperNavLogo />
    <button class="menu-toggle" @click="toggleMenu">
      <span v-if="!menuOpen">&#9776;</span>
      <span v-else>&#10006;</span>
    </button>
    <ul :class="['navbar-links', { 'is-active': menuOpen }]">
      <button
        @click="selectView('ChurchInfo')"
        :class="['navbar-btn', { selected: selectedView === 'ChurchInfo' }]"
      >
        Church Info
      </button>
      <button
        v-if="admin && admin.homeScreenAccess"
        @click="selectView('AppHomeScreen')"
        :class="['navbar-btn', { selected: selectedView === 'AppHomeScreen' }]"
      >
        App Home Screen
      </button>
      <button
        v-if="admin && admin.broadcastAccess"
        @click="selectView('MassNotifications')"
        :class="[
          'navbar-btn',
          { selected: selectedView === 'MassNotifications' },
        ]"
      >
        Mass Notifications
      </button>
      <button
        v-if="admin && admin.publicEventsAccess"
        @click="selectView('Events')"
        :class="['navbar-btn', { selected: selectedView === 'Events' }]"
      >
        Events
      </button>
      <button
        v-if="admin && admin.adminsAccess"
        @click="selectView('Admins')"
        :class="['navbar-btn', { selected: selectedView === 'Admins' }]"
      >
        Admins
      </button>
      <button @click="logout()" :class="'navbar-btn'">Logout</button>
    </ul>
  </nav>
</template>

<script>
import axios from "axios";
import { useAdminStore } from "@/stores/admin";
import {
  USER_ID,
  TOKEN,
  USER_EMAIL,
  DEVICE_UNIQUE_ID,
} from "../constants/storageKeys";

export default {
  name: "AdminNavBar",
  props: {
    selectedView: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedViewCopy: this.selectedView, // Create a local copy of the prop
      menuOpen: false,
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    selectedView(newVal) {
      this.selectedViewCopy = newVal;
    },
  },
  computed: {
    admin() {
      const adminStore = useAdminStore();
      return adminStore.admin;
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    selectView(view) {
      this.selectedViewCopy = view; // Update the local copy
      this.$emit("select-view", view); // Notify the parent of the change
      this.menuOpen = false; // Auto-collapse the menu
    },
    async logout() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

        // Call the logout endpoint
        const token = localStorage.getItem(TOKEN);
        if (token) {
          await axios.post(
            `${apiBaseUrl}/logout`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        // Clear local storage
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(USER_EMAIL);
        localStorage.removeItem(DEVICE_UNIQUE_ID);

        // Redirect to login page
        this.$router.push("/login");
        alert("You have been logged out successfully.");
      } catch (error) {
        console.error("Logout failed:", error);
        alert("logged out with errors");
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(USER_EMAIL);
        localStorage.removeItem(DEVICE_UNIQUE_ID);

        // Redirect to login page
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #07538f;
  color: white;
}

.navbar-brand a {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.menu-toggle {
  font-size: 1.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 1.5rem;
}

.navbar-btn {
  color: white;
  background-color: #07538f;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.navbar-btn.selected {
  background-color: #ff8500; /* Highlight color for selected button */
}

.navbar-links.is-active {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #07538f;
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    z-index: 10;
  }

  .navbar-links.is-active {
    display: flex;
  }
}
</style>
