import { defineStore } from "pinia";

export const useSubscriptionStore = defineStore("subscription", {
  state: () => ({
    subscription: null, // Holds the subscription data
  }),
  actions: {
    /**
     * Update the subscription data
     */
    updateSubscription(subscriptionData) {
      this.subscription = subscriptionData;
    },
  },
});
