<template>
  <div style="text-align: center; position: relative">
    <!-- Loading State -->
    <div v-if="loading" class="loading">Loading plans...</div>

    <!-- Available Plans Section -->
    <div v-if="!loading" class="subscription-tiers">
      <h2>Manage Your Plan</h2>
      <!-- Toggle Switch -->
      <div class="subscription-toggle">
        <label>
          <span> Save One Month with a Yearly Subscription </span>
          <input type="checkbox" v-model="showYearly" />
        </label>
      </div>
      <div class="tiers">
        <div
          v-for="plan in plans"
          :key="plan.id"
          :class="[
            'tier',
            { 'current-plan': plan.name === subscription.stripeProductName },
          ]"
        >
          <!-- Plan Name -->
          <h3>
            {{ plan.name }}
            <span
              v-if="plan.name === subscription.stripeProductName"
              class="badge"
              >Current Plan</span
            >
          </h3>

          <!-- Plan Features -->
          <ul style="text-align: left">
            <li
              v-for="feature in plan.features"
              :key="feature"
              v-html="feature"
            ></li>
          </ul>

          <!-- Plan Pricing -->
          <p class="price">
            {{
              showYearly && plan.pricing.annual
                ? formatPrice(
                    plan.pricing.annual.unit_amount,
                    plan.pricing.annual.currency
                  )
                : formatPrice(
                    plan.pricing.monthly.unit_amount,
                    plan.pricing.monthly.currency
                  )
            }}
            {{ showYearly ? "PER YEAR" : "PER MONTH" }}
          </p>

          <!-- Subscribe Button -->
          <button
            :disabled="plan.name === subscription.stripeProductName"
            class="common-btn"
            @click="selectPlan(plan)"
          >
            {{
              plan.name === subscription.stripeProductName
                ? "Current Plan"
                : "Subscribe Now"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useSubscriptionStore } from "@/stores/subscription";
import { useAdminStore } from "@/stores/admin";
import axios from "axios";

export default {
  data() {
    return {
      plans: [], // Available plans
      showYearly: false, // Toggle between monthly and yearly prices
      loading: true, // Loading state
    };
  },
  computed: {
    subscription() {
      const subscriptionStore = useSubscriptionStore();
      return subscriptionStore.subscription;
    },
    isMainAdmin() {
      const adminStore = useAdminStore();
      return adminStore.admin.isMainAdmin;
    },
  },
  methods: {
    async fetchPlans() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${apiBaseUrl}/products`);
        if (response.data.success) {
          const plansArray = Object.values(response.data.data);

          // Sort the plans based on subscription_page_order
          this.plans = plansArray.sort((a, b) => {
            const aOrder =
              parseInt(a.metadata.subscription_page_order, 10) || 0;
            const bOrder =
              parseInt(b.metadata.subscription_page_order, 10) || 0;
            return aOrder - bOrder;
          });
        } else {
          console.error("Failed to fetch plans:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        this.loading = false;
      }
    },
    formatPrice(amount, currency) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency.toUpperCase(),
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount / 100);
    },
    selectPlan(plan) {
      if (!this.isMainAdmin) {
        alert(
          "Only the main admin and account holder can update the subscription. Please reach out to the main admin of your church."
        );
        return;
      }
      const selectedBillingCycle = this.showYearly ? "Yearly" : "Monthly";
      let selectedPrice = null;
      let priceId = null;

      if (this.showYearly && plan.pricing.annual) {
        selectedPrice = plan.pricing.annual.unit_amount;
        priceId = plan.pricing.annual.id;
      } else if (plan.pricing.monthly) {
        selectedPrice = plan.pricing.monthly.unit_amount;
        priceId = plan.pricing.monthly.id;
      } else {
        console.error(
          "No valid pricing available for the selected plan and billing cycle."
        );
        return;
      }

      // Emit the selected plan details, including the subscriptionId
      this.$emit("planSelected", {
        ...plan,
        billingCycle: selectedBillingCycle,
        price: selectedPrice,
        priceId: priceId,
        subscriptionId: this.subscription.stripeSubscriptionId,
        customerId: this.subscription.stripeCustomerId,
      });
    },
  },
  mounted() {
    this.fetchPlans(); // Fetch the plans on component mount
  },
};
</script>
<style scoped>
.subscription-toggle {
  margin: 20px 0;
}

.subscription-toggle label {
  font-size: 1rem;
  color: #07538f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.subscription-toggle input {
  appearance: none;
  width: 40px;
  height: 20px;
  background: #ddd;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
}

.subscription-toggle input:checked {
  background: #07538f;
}

.subscription-toggle input:checked::before {
  transform: translateX(20px);
}

.subscription-toggle input::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.tiers {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.tier {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.tier.current-plan {
  border-color: #07538f; /* Highlight the current plan */
  background-color: #f0f8ff; /* Slightly different background for the current plan */
}

.tier .badge {
  background-color: #ff8500;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75rem;
  position: absolute;
  top: 10px;
  right: 10px;
}

.common-btn {
  background-color: #07538f; /* Button background */
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.common-btn:disabled {
  background-color: #ddd; /* Disable button for current plan */
  cursor: not-allowed;
}

.common-btn:hover:not(:disabled) {
  background-color: #00426c; /* Button hover effect */
}

.loading {
  font-size: 1.5rem;
  color: #07538f;
}
</style>
