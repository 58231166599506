<template>
  <div class="dashboard-container">
    <AdminNavBar :selected-view="currentView" @select-view="setCurrentView" />

    <div v-if="currentView === 'ChurchInfo'" class="page">
      <!-- Pass churchData as a prop to ChurchInfo -->
      <ChurchInfo
        v-if="churchData"
        :churchData="churchData"
        @select-view="setCurrentView"
      />
    </div>

    <div v-if="currentView === 'AppHomeScreen'" class="page">
      <AppHomeScreen
        v-if="churchData"
        :churchId="churchData.church.id"
        :token="token"
      />
    </div>

    <div v-if="currentView === 'MassNotifications'" class="page">
      <MassNotifications
        :churchId="churchData.church.id"
        :token="token"
        :urlName="churchData.church.urlName"
        :userId="this.userId"
      />
    </div>

    <div v-if="currentView === 'Events'" class="page">
      <Events :churchId="churchData.church.id" :token="token" />
    </div>

    <div v-if="currentView === 'Admins'" class="page">
      <Admins :churchId="churchData.church.id" />
    </div>

    <MyChelperFooter />
  </div>
</template>

<script>
import { useSubscriptionStore } from "@/stores/subscription";
import { useAdminStore } from "@/stores/admin";
import { USER_ID, TOKEN } from "../constants/storageKeys";
import axios from "axios";
import AdminNavBar from "@/components/AdminNavBar.vue";
import ChurchInfo from "@/components/AdminDashboard/ChurchInfo.vue";
import AppHomeScreen from "@/components/AdminDashboard/AppHomeScreen.vue";
import MassNotifications from "@/components/AdminDashboard/MassNotifications.vue";
import Events from "@/components/AdminDashboard/Events/Events.vue";
import Admins from "@/components/AdminDashboard/AdminsComponent.vue";
import { EventBus } from "@/utils/eventBus.js";

export default {
  name: "DashboardView",
  data() {
    return {
      currentView: "ChurchInfo",
      churchData: null,
      userId: localStorage.getItem(USER_ID) ?? "",
      token: localStorage.getItem(TOKEN) ?? "",
    };
  },
  components: {
    AdminNavBar,
    ChurchInfo,
    AppHomeScreen,
    MassNotifications,
    Events,
    Admins,
  },
  methods: {
    setCurrentView(view) {
      this.currentView = view;
    },
    async fetchChurchData() {
      try {
        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.post(
          `${apiBaseUrl}/church-admin-data`,
          {
            userId: this.userId, // Define `userId` here or pass it in as a prop
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`, // Define `token` here or pass it in as a prop
              "Content-Type": "application/json",
            },
          }
        );

        this.churchData = response.data; // Store the response data

        const subscriptionStore = useSubscriptionStore();
        const subscription = this.churchData.church?.subscriptions[0] ?? null;
        subscriptionStore.updateSubscription(subscription);

        const adminStore = useAdminStore();
        adminStore.updateAdmin(this.churchData);
      } catch (error) {
        console.error("Failed to load church data:", error);
      }
    },
  },
  mounted() {
    EventBus.on("refreshSubscription", this.fetchChurchData);
  },
  beforeUnmount() {
    EventBus.off("refreshDashboard", this.fetchChurchData);
  },
  created() {
    this.fetchChurchData(); // Fetch data on component creation
  },
};
</script>
